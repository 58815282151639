import 'styled-components'
import { css, DefaultTheme } from 'styled-components'
import redeLogo from 'assets/logos/logo-rede.svg'
import loginBackgroundRede from 'assets/images/login-background-rede.svg'
import wizardBackgroundRede from 'assets/images/wizard-background-rede.png'

declare module 'styled-components' {
  export interface DefaultTheme {
    domain: string
    assets: { [key in keyof typeof assets]: any }
    colors: { [key in keyof typeof themeColors]: string }
    fontTypes: { [key in keyof typeof fontTypes]: any }
    fontFamily: string
    gutter: number
    grid: { [key in keyof typeof gridConfig]: any }
    mediaQueries: { [key in keyof typeof mediaQueries]: string }
    shadows: { [key in keyof typeof shadows]: string }
    spacing: (x: number) => number
  }
}

export const themeColors = {
  transparent: 'transparent',
  navbarvector1: '#FFA03F',
  lightmaincolor4: '#EC7000',
  lightmaincolor3: '#FFE5CC',
  lightmaincolor2: '#FFC080',
  lightmaincolor1: '#FFA03F',
  maincolor: '#EC7000',
  transparentmaincolor: '#EC7000BF',
  darkmaincolor1: '#D05900',
  darkmaincolor2: '#B34100',
  darkmaincolor3: '#662500',
  lightred1: '#662500',
  lightred2: '#FF9999',
  redshipay: '#E33B3B',
  black: '#000',
  graytheme1: '#FEF6EF',
  graytheme2: '#CFDCE6',
  graytheme3: '#CCCCCC',
  graytheme4: '#A3BACC',
  graytheme5: '#6B8599',
  graytheme6: '#595959',
  graytheme7: '#808080',
  graytheme8: '#394F60',
  graytheme9: '#B3B3B3',
  whiteshipay: '#FFFFFF',
  gray1: '#605f5f',
  gray2: '#CBCBCB',
  gray3: '#dedede',
  gray4: '#EDF1F7',
  white: '#FFFFFF',
  whitesmoke: '#f4f4f4',
  yellow1: '#fbce33',
  green1: '#4CAF50',
  orange: '#E5955C',
  pix: '#09B2A5',
  ame: '#fe4763',
  mercadopago: '#49CBF2',
  pixmercadopago: '#49CBF2',
  picpay: '#33CC6D',
  pagseguro: '#2EE5C3',
  pixpagseguro: '#2EE5C3',
  cielopay: '#0BABE5',
  pixitau: '#F98F53',
  itau: '#F98F53',
  pixbb: '#F5CC00',
  bb: '#F5CC00',
  bnb: '#D63D63',
  bs2: '#6060EB',
  pixsantander: '#FE6565',
  santander: '#FE6565',
  pixbradesco: '#FF5977',
  bradesco: '#FF5977',
  cielo: '#19C1FF',
  tecban: '#FF595F',
  coinpayments: '#337BFF',
  foxbit: '#FA9242',
  sicoob: '#BDDA1B',
  ninetyninepay: '#FFDE33',
  portobank: '#49B4F2',
  pixportobank: '#49B4F2',
  sicredi: '#70D63D',
  pixsicredi: '#70D63D',
  celcoin: '#7864FA',
  pixcelcoin: '#7864FA',
  blueGradient: `linear-gradient(#56ccf2,#027ad3)`,
  tableColor1: ' #FEF6EF',
  tableColor2: '#FDFCFB',
  tableColor3: '#FEF6EF',
  tableColor4: '#FDFCFB',
  tableColor5: '#FBEFE5',
  tableColor6: '#FAF3ED',
  tableColor7: '#F7F7F7',
  tableColor8: '#FCFCFC',
  indianred: 'indianred',
  headerColor: '#f7f8ff',
  approvedStatusBackground: '#E1EEFA',
  approvedStatusIconColor: '#027ad3',
  approvedStatusTextColor: '#2B5D82',
  graytheme10: '#EDEDED',
  pendingStatusIconColor: '#808080',
  pendingStatusTextColor: '#575757',
  cancelledStatusBackground: '#F5E6E6',
  cancelledStatusIconColor: '#E55C5C',
  cancelledStatusTextColor: '#822B2B',
  detailsModalHeaderInfoColor: '#A1EEF7',
  detailsModalKeyMessageColor: '#F7E9A1',
}

export const shadows = {
  gray1: '0px 1px 2px rgba(39, 39, 39, 0.14)',
  gray2: '0px 1px 8px 2px #B8CBD9',
  gray2hover: '0px 1px 8px 3px #B8CBD9',
  blue1: '0px 3px 6px rgba(0, 173, 239, 0.14)',
  blue3hover: '0px 1px 8px 3px rgba(92, 170, 229, 0.5)',
  blue4pressed: '0px 1px 8px 4px rgba(92, 170, 229, 0.75)',
}

export const gridConfig = {
  breakpoints: {
    xl: 1280,
    lg: 1280,
    md: 980,
    sm: 736,
    xs: 480,
  },
  row: {
    padding: 8,
  },
  col: {
    padding: 8,
  },
  container: {
    padding: 8,
    maxWidth: {
      xl: 1280,
      lg: 1280,
      md: 980,
      sm: 736,
      xs: 480,
    },
  },
}

export const mediaQueries = {
  xs: `(max-width: ${gridConfig.breakpoints.xs}px)`,
  sm: `(max-width: ${gridConfig.breakpoints.sm}px)`,
  md: `(max-width: ${gridConfig.breakpoints.md}px)`,
  lg: `(max-width: ${gridConfig.breakpoints.lg}px)`,
  xl: `(max-width: ${gridConfig.breakpoints.xl}px)`,
  smUp: `(min-width: ${gridConfig.breakpoints.sm}px)`,
  mdUp: `(min-width: ${gridConfig.breakpoints.md}px)`,
  lgUp: `(min-width: ${gridConfig.breakpoints.lg}px)`,
  xlUp: `(min-width: ${gridConfig.breakpoints.xl}px)`,
}

const genericFontType = {
  fontFamily: 'FontInter',
}

export const fontTypes = {
  heading1: {
    ...genericFontType,
    fontSize: 92,
    letterSpacing: 0,
    lineHeight: '100px',
  },
  heading2: {
    ...genericFontType,
    fontSize: 40,
    letterSpacing: 0,
    lineHeight: '48px',
  },
  heading3: {
    ...genericFontType,
    fontSize: 30,
    letterSpacing: 0,
    lineHeight: '46px',
  },
  heading4: {
    ...genericFontType,
    fontSize: 24,
    letterSpacing: 0,
    lineHeight: '40px',
  },
  headline: {
    ...genericFontType,
    fontSize: 18,
    letterSpacing: 0,
    lineHeight: '25px',
  },
  paragraph: {
    ...genericFontType,
    fontSize: 16,
    letterSpacing: 0,
    lineHeight: '21px',
  },
  body: {
    ...genericFontType,
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '19px',
  },
  button: {
    fontSize: 11,
    fontWeight: 600,
    letterSpacing: 1.5,
    textTransform: 'uppercase',
    lineHeight: '15px',
  },
  tag: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '15px',
  },
}

export const assets = {
  mainLogo: redeLogo,
  login: {
    backgroundImg: loginBackgroundRede,
    subtitle: false,
    marginCardTop: '0px',
  },
  wizardBackground: {
    img: wizardBackgroundRede,
    filter: '0',
  },
  header: {
    background1: `
      radial-gradient(
        50% 50% at 50% 50%,
        #FFA03F 10.89%,
        rgba(41, 122, 204, 0) 100%
      );
    `,
    background2: `
      linear-gradient(
        108.21deg,
        #ffffff 16.57%,
        rgba(255, 255, 255, 0) 86.15%
      ), #EC7000;
    `,
    borderBottom: `
      1px solid #FFC080
    `,
    listItem: {
      selected: `
        background-color: ${themeColors.lightmaincolor3};
        box-shadow: inset 0px 1px 8px 2px
          ${themeColors.lightmaincolor2};

        > a > h5 {
          color: ${themeColors.maincolor};
        }
      `,
    },
    listItemHover: `
      > a > h5 {
        color: ${themeColors.maincolor};
      }
      * > svg {
        fill: ${themeColors.maincolor};
      }
    `,
  },
  buttonComponent: {
    shadow: `
    0px 1px 4px ${themeColors.graytheme9};;
    `,
  },
  modalComponent: {
    padding: '10px 16px',
    modalHeader: `
      align-items: center;
      display: flex;
      padding: 16px;
    `,
  },
  inputComponent: {
    inputTextShadow: `
      0px 1px 8px 2px #B8CBD9
    `,
    inputTextShadowHover: `
      0px 1px 8px 3px rgba(92, 170, 229, 0.5);
    `,
  },
  tableComponent: {
    tableHeader: `
      height: 60px;
      background: linear-gradient(
        180deg,
        ${themeColors.tableColor1} 0%,
        ${themeColors.tableColor2}28.57%,
        ${themeColors.tableColor2}71.42%,
        ${themeColors.tableColor1} 100%
      );
      color: ${themeColors.gray1}
    `,
  },
  orders: {
    bold: true,
    registerIcon: 'register',
    storeIcon: 'store',
    pageHeader: css`
      display: flex;
      align-items: flex-start;
      height: 50px;

      /* & > p {
        :first-child {
          margin-right: ${({ theme }) => theme.spacing(2)}px;
          background: linear-gradient(
            ${themeColors.lightmaincolor4},
            ${themeColors.maincolor}
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      } */
    `,
    tableBorderColor: themeColors.graytheme3,
    table: `
    > tbody > tr > td > * > p {
     :not(:nth-child(7), :last-child) {
       font-weight: 500;
     }
    }
     * > th {
       :first-child {
         width: 14%;
       }
       :nth-child(2) {
         width: 12%;
       }
       :nth-child(3) {
         width: 14%;
       }
       :nth-child(4) {
         width: 14%;
       }
       :nth-child(5) {
         width: 14%;
       }
       :nth-child(6){
         width: 10%
       }
       :nth-child(7) {
         width: 12%;
          h5 {
           color: ${themeColors.graytheme6};
         }
       }
       :nth-child(8) {
         padding-left: 16px;
       }
     }
   `,
    columns: {
      seeMoreButton: `
      padding: 8px;
      border-radius: 8px;
      border: 0;
      width: 80px;
      background-color: transparent;
    
      :hover {
        cursor: pointer;
        background-color: ${themeColors.lightmaincolor3};
      }
    
      :active {
        background-color: ${themeColors.lightmaincolor2};
      }
        
        @media ${mediaQueries.lgUp} {
          width: 93px;
        }
      `,
      uuidContainer: `
        color: ${themeColors.maincolor};
        padding: 8px;
        border-radius: 8px;
        display: flex;
        align-items: center;

        :hover {
          cursor: pointer;
          background-color: ${themeColors.lightmaincolor3};
        }
        
        :active {
          background-color: ${themeColors.lightmaincolor2};
        }

        > svg {
          fill: ${themeColors.maincolor};
          weight: 200;
        }
      `,
      status: ``,
    },
  },
}

const theme: DefaultTheme = {
  domain: 'ConexãoItaú',
  assets: assets,
  colors: themeColors,
  fontTypes,
  fontFamily: 'Inter',
  gutter: 14,
  grid: gridConfig,
  mediaQueries,
  shadows,
  spacing: (number) => number * 8,
}

export default theme
