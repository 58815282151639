import { Dispatch, SetStateAction, useState } from 'react'
import { WallletSettingInfo } from '../EditItauAnybank'
import { showToast } from 'components/Toast'
import { formatPixKey, getPixKeyType, normalize } from 'helpers/pix'
import { applyCnpjMask } from 'helpers/masks'

import { baseURLRegistrationV2DestinationAccounts, patch } from 'services/api'

import * as s from '../styles'
import { AccordionBox } from './AccordionBox'
interface ThirdSectionProps
  extends Pick<WallletSettingInfo, 'destination_account'> {
  allowedToUpdate?: boolean
  currentWalletSettingInfo: WallletSettingInfo
  updateWalletSettingInfo: Dispatch<SetStateAction<WallletSettingInfo>>
}

interface PayloadPatchDestinationAccount {
  name: string
  cpf_cnpj: string
  pix_dict_key: string
  pix_dict_key_type: 'cpf' | 'cnpj' | 'phone' | 'email' | 'random_key'
}

const handleErrorMessage = (error: any) => {
  let title = ''
  let message = ''

  if (!Array.isArray(error.response?.data?.detail)) {
    title = 'Erro ao atualizar'
    message =
      error.response?.data?.detail || 'Não foi possível atualizar o campo.'
  } else {
    title =
      error.response?.data?.detail[0]?.loc[1] === 'pix_dict_key' ||
      error.response?.data?.detail[0]?.loc[1] === 'dict_key'
        ? 'Problema ao atualizar Chave Pix.'
        : 'Algo deu errador.'
    message =
      error.response.data.detail[0].msg ||
      error.response.data?.detail ||
      'Não foi possível atualizar o campo'
  }

  const translateMessage = (message: string): string => {
    switch (message) {
      case 'CNPJ/CPF not valid or not well formed':
        return 'CNPJ ou CPF inválido.'
      case 'CPF not valid or not well formed':
        return 'CPF inválido.'
      case 'The phone number provided seems to be not in a valid format':
        return 'Formato do telefone inválido.'
      case 'Email not valid or not well formed':
        return 'Email inválido.'
      case 'Random Key not valid UUID':
        return 'Chave aleatória inválida.'
      case 'pix_dict_key and pix_dict_key_type must be provided together':
        return 'Chave Pix e tipo devem ser fornecidos.'
      default:
        return message
    }
  }

  return {
    title,
    errorMessage: translateMessage(message),
  }
}

const InfoBox: React.FC<{
  error?: { title: string; message: React.ReactElement }
}> = ({ error }) => {
  if (error?.title) {
    return (
      <s.InfoBox data-testid="info-box-error" margin="-16px 0px 0px 0px" error>
        <s.Text bold type="paragraph" color="redshipay">
          {error.title}
        </s.Text>
        <s.Text type="paragraph" color="cancelledStatusTextColor">
          {error.message}
        </s.Text>
      </s.InfoBox>
    )
  }

  return <></>
}

const ThirdSection: React.FC<ThirdSectionProps> = ({
  destination_account,
  allowedToUpdate,
  currentWalletSettingInfo,
  updateWalletSettingInfo,
}) => {
  const [destinationAccount, setDestinationAccount] =
    useState(destination_account)

  const [onEditMode, setOnEditMode] = useState(false)
  const [patchIsLoading, setPatchIsLoading] = useState(false)
  const [infoError, setInfoError] = useState<{
    title: string
    message: React.ReactElement
  }>({
    title: '',
    message: <></>,
  })

  const showAccordion = !allowedToUpdate

  const updatePixDictKey = async () => {
    setPatchIsLoading(true)

    const payload: PayloadPatchDestinationAccount = {
      name: currentWalletSettingInfo.pix_account_details.wallet_setting_name,
      cpf_cnpj: destination_account.cpf_cnpj,
      pix_dict_key: normalize(destinationAccount.pix_dict_key),
      pix_dict_key_type: getPixKeyType(destinationAccount.pix_dict_key),
    }

    await patch(
      `${baseURLRegistrationV2DestinationAccounts}/${destination_account.id}/stores`,
      payload
    )
      .then((res) => {
        updateWalletSettingInfo({
          ...currentWalletSettingInfo,
          destination_account: destinationAccount,
        })
        setOnEditMode(false)
      })
      .catch((e) => {
        setInfoError({
          title: handleErrorMessage(e).title,
          message: <> {handleErrorMessage(e).errorMessage}</>,
        })
        setDestinationAccount({
          ...destination_account,
        })
      })
      .finally(() => {
        setPatchIsLoading(false)
      })
  }

  return (
    <s.Section data-testid="third-section">
      <s.SectionHeader>
        <s.TitleWrapper>
          <s.Icon name="bank" fill="gray1" />
          <s.Text type="headline" color="graytheme6">
            Conta Destino
          </s.Text>
        </s.TitleWrapper>
        {!onEditMode && allowedToUpdate ? (
          <s.Button
            onClick={() => setOnEditMode(true)}
            width="98px"
            color="white"
            data-testid="edit-third-section-button"
          >
            <s.Icon name="pencil" fill="maincolor" />
            <s.Text color="maincolor" type="headline" fontWeight={600}>
              Editar
            </s.Text>
          </s.Button>
        ) : (
          <></>
        )}
      </s.SectionHeader>
      <InfoBox error={infoError} />
      <s.SectionLine>
        <s.Text margin="0px 0px 16px 0px" type="headline" color="graytheme6">
          CNPJ
        </s.Text>
        <s.InputText
          data-testid="cnpj-input"
          width="300"
          value={applyCnpjMask(destinationAccount.cpf_cnpj)}
          disabled={true}
          onChange={() => {}}
        />
      </s.SectionLine>
      <s.SectionLine>
        <s.Text margin="0px 0px 16px 0px" type="headline" color="graytheme6">
          Chave Pix
        </s.Text>
        <s.InputText
          testId="pix-dict-key-input"
          width="300"
          value={formatPixKey(destinationAccount.pix_dict_key)}
          error={
            !destinationAccount.pix_dict_key
              ? { message: 'campo obrigatório' }
              : false
          }
          disabled={!onEditMode || patchIsLoading}
          onChange={(e) => {
            setDestinationAccount({
              ...destinationAccount,
              pix_dict_key: formatPixKey(e.target.value),
            })
          }}
        />
      </s.SectionLine>
      {showAccordion ? <AccordionBox /> : <></>}
      {onEditMode ? (
        <s.SectionLine>
          <div></div>
          <s.ButtonWrapper>
            <s.CancelButton
              disabled={patchIsLoading}
              onClick={() => {
                setOnEditMode(false)
                setDestinationAccount(destination_account)
                setInfoError({ title: '', message: <></> })
              }}
              width="98px"
              color="white"
            >
              <s.Text color="maincolor" type="headline" fontWeight={600}>
                Cancelar
              </s.Text>
            </s.CancelButton>

            <s.Button
              data-testid="pix-dict-key-save-button"
              onClick={() => {
                setInfoError({ title: '', message: <></> })
                updatePixDictKey()
              }}
              disabled={
                !destinationAccount.pix_dict_key ||
                patchIsLoading ||
                normalize(destinationAccount.pix_dict_key) ==
                  normalize(destination_account.pix_dict_key)
              }
              color="pixitau"
            >
              {patchIsLoading ? (
                <s.Loading type="spinner" color="white" />
              ) : (
                <s.Icon name="pencilUnderline" fill="white" />
              )}
              <s.Text color="white" type="headline" fontWeight={600}>
                Salvar edição
              </s.Text>
            </s.Button>
          </s.ButtonWrapper>
        </s.SectionLine>
      ) : (
        <></>
      )}
      {onEditMode && (
        <s.SectionLine>
          <s.WarningCard>
            <s.Text type="body" fontWeight={400}>
              Sua chave deve ser vinculada a uma conta de mesmo CNPJ do
              contratante.
            </s.Text>
          </s.WarningCard>
        </s.SectionLine>
      )}
    </s.Section>
  )
}

export default ThirdSection
