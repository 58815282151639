export const Types = {
  GET_SYSTEM_WALLETS: 'GET_SYSTEM_WALLETS',
  GET_SYSTEM_WALLETS_LOADING: 'GET_SYSTEM_WALLETS_LOADING',
  GET_SYSTEM_WALLETS_ERROR: 'GET_SYSTEM_WALLETS_ERROR',
}

export interface SystemWalletsInterface {
  active: boolean
  id: number
  single_certificate: boolean
  single_credentials: boolean
  wallet_friendly_name: string
  wallet_logo: any
  wallet_name: string
  wallet_type: string
  wallet_icon?: string
  cashout_active: boolean
  features?: Array<string>
}

export interface PayloadInterface {
  wallets: SystemWalletsInterface[]
}

export interface ActionInterface {
  type: keyof typeof Types
  payload: PayloadInterface
}

export interface SystemWalletsState {
  systemWallets: SystemWalletsInterface[]
  isLoading: boolean
  hasError: boolean
}
