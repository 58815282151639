import React from 'react'
import loadingbigger from 'assets/images/loading-bigger.png'
import * as s from './styles'
import { Steps } from '.'

interface Props {
  step: Steps
  hasSplitFee: boolean
}
const Loading: React.FC<Props> = ({ step, hasSplitFee }) => {
  if (step.form) {
    return (
      // <s.Sections data-testid="content-on-loading">
      <s.Section>
        <s.Text margin="0px 0 32px 0" color="graytheme6" type="headline">
          Buscando dados...
        </s.Text>
        <s.LoadingWrapper>
          <s.Loading color="maincolor" type="bigspinner" width={110} />
        </s.LoadingWrapper>
      </s.Section>
      // </s.Sections>
    )
  }

  return (
    <s.Sections data-testid="content-on-loading">
      <s.LoadingWrapper>
        <s.Text color="graytheme6" bold type="heading4">
          {hasSplitFee ? 'Atualizando tarifa...' : 'Cadastrando tarifa...'}
        </s.Text>
        <s.Text margin="32px 0 72px 0" color="graytheme6" type="headline">
          Deve levar alguns segundos. <br /> Por favor, permaneça nessa página.
        </s.Text>
        <s.Loading color="maincolor" type="bigspinner" width={110} />
      </s.LoadingWrapper>
    </s.Sections>
  )
}

export default Loading
