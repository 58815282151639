import { showToast } from 'components/Toast'
import { useEffect, useState } from 'react'
import { baseURLBffPanelWalletSettings, get } from 'services/api'
import * as s from './styles'
import React from 'react'
import { getPixKeyType } from 'helpers/pix'

import FirstSection from './components/EditItauAnybankFirstSection'
import SecondSection from './components/EditItauAnybankSecondSection'
import ThirdSection from './components/EditItauAnybankThirdSection'
import FourthSection from './components/EditItauAnybankFourthSection'
import loadingbigger from 'assets/images/loading-bigger.png'

interface IProps {
  isOpen: boolean
  handleClose: () => any
  editSettingsInfo: { id: string; pspProvider: string }
  certList: { id: string; text: string }[]
}
export type PartnerWalletSettingsStatus = 'active' | 'inactive' | 'error' | null

const INITIAL_INFO_STATE: WallletSettingInfo = {
  destination_account: {
    cpf_cnpj: '',
    pix_dict_key: '',
    id: '',
  },
  fee_value: null,
  minimum_fee: null,
  more_details: {
    tls_certificate: '',
    wallet_setting_dict_key: '',
    partner_wallet_settings_status: null,
  },
  pix_account_details: {
    wallet_setting_name: '',
  },
  split_fee_type: '',
  wallet_icon: '',
  wallet_friendly_name: '',
  wallet_setting_transaction_type: '',
  wallet_setting_uuid: '',
}

export interface WallletSettingInfo {
  destination_account: {
    cpf_cnpj: string
    pix_dict_key: string
    id: string
    is_editable?: boolean
  }
  fee_value: number
  minimum_fee: number
  more_details: {
    tls_certificate: string | null
    wallet_setting_dict_key: string | null
    partner_wallet_settings_status?: PartnerWalletSettingsStatus
  }
  pix_account_details: {
    wallet_setting_name: string
  }
  split_fee_type: string
  wallet_friendly_name: string
  wallet_icon: string
  wallet_setting_transaction_type: string
  wallet_setting_uuid: string
}

export const SettingsWSFormItauAnybank: React.FC<IProps> = ({
  isOpen,
  handleClose,
  editSettingsInfo,
  certList,
}) => {
  const [walletSettingInfo, setWalletSettingInfo] =
    React.useState(INITIAL_INFO_STATE)
  const [onLoadingDataInfo, setOnLoadingDataInfo] = useState(true)
  const loadWlSettingsInfo = (id: string, isMounted: boolean) => {
    get(`${baseURLBffPanelWalletSettings}/${id}/splits`)
      .then((res) => {
        setWalletSettingInfo(res.data)
        setOnLoadingDataInfo(false)
      })
      .catch((e) => {
        showToast({
          type: 'error',
          message: e.request.response?.detail
            ? JSON.parse(e.request.response)?.detail
            : 'Erro na API, tente novamente mais tarde',
        })
        if (isMounted) {
          setOnLoadingDataInfo(false)
        }
      })
  }

  const showMoreDetails = (): boolean => {
    if (walletSettingInfo.more_details)
      return !!(
        walletSettingInfo.more_details.tls_certificate &&
        walletSettingInfo.more_details.wallet_setting_dict_key
      )
    return false
  }

  useEffect(() => {
    let isMounted = true

    loadWlSettingsInfo(editSettingsInfo.id, isMounted)
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <s.Modal
      handleClose={() => handleClose()}
      isOpen={isOpen}
      noChildrenPadding
      modalSubtitle="Configurações"
      showScroll
    >
      <s.ModalContentItauAnybank data-testid="settings-modal-content-itau-anybank">
        {onLoadingDataInfo ? (
          <s.AnybankLoadingWrapper data-testid="in-loading-section">
            <s.AnybankLoadingGradient data-testid="on-loading">
              <img width={80} src={loadingbigger} />
            </s.AnybankLoadingGradient>
          </s.AnybankLoadingWrapper>
        ) : (
          <s.SectionsWrapper data-testid="in-content-section">
            <FirstSection
              pspProvider={editSettingsInfo.pspProvider}
              fee_value={walletSettingInfo.fee_value}
              minimum_fee={walletSettingInfo.minimum_fee}
              split_fee_type={walletSettingInfo.split_fee_type}
              wallet_friendly_name={walletSettingInfo.wallet_friendly_name}
              wallet_icon={walletSettingInfo.wallet_icon}
              wallet_setting_transaction_type={
                walletSettingInfo.wallet_setting_transaction_type
              }
            />
            <s.SectionLineDivider />
            <SecondSection
              wallet_setting_uuid={walletSettingInfo.wallet_setting_uuid}
              pix_account_details={walletSettingInfo.pix_account_details}
              currentWalletSettingInfo={walletSettingInfo}
              updateWalletSettingInfo={setWalletSettingInfo}
            />
            <s.SectionLineDivider />
            <ThirdSection
              destination_account={
                getPixKeyType(
                  walletSettingInfo.destination_account.pix_dict_key
                ) === 'phone'
                  ? {
                      ...walletSettingInfo.destination_account,
                      pix_dict_key:
                        walletSettingInfo.destination_account.pix_dict_key.replace(
                          '+55',
                          ''
                        ),
                    }
                  : walletSettingInfo.destination_account
              }
              allowedToUpdate={
                walletSettingInfo.destination_account.is_editable ?? false
              }
              currentWalletSettingInfo={walletSettingInfo}
              updateWalletSettingInfo={setWalletSettingInfo}
            />
            {showMoreDetails() ? (
              <>
                <s.SectionLineDivider />
                <FourthSection
                  wallet_setting_uuid={walletSettingInfo.wallet_setting_uuid}
                  more_details={walletSettingInfo.more_details}
                  currentWalletSettingInfo={walletSettingInfo}
                  updateWalletSettingInfo={setWalletSettingInfo}
                  certList={certList}
                />
              </>
            ) : null}
          </s.SectionsWrapper>
        )}
      </s.ModalContentItauAnybank>
    </s.Modal>
  )
}
