import { WallletSettingInfo } from '../EditItauAnybank'
import * as s from '../styles'

interface FirstSectionProps
  extends Pick<
    WallletSettingInfo,
    | 'fee_value'
    | 'minimum_fee'
    | 'split_fee_type'
    | 'wallet_friendly_name'
    | 'wallet_icon'
    | 'wallet_setting_transaction_type'
  > {
  pspProvider: string
}

const FirstSection: React.FC<FirstSectionProps> = ({
  pspProvider,
  fee_value,
  minimum_fee,
  split_fee_type,
  wallet_friendly_name,
  wallet_icon,
  wallet_setting_transaction_type,
}) => {
  const isPspProviderAnybank = (
    pspProvider: string,
    transactionType: string
  ) => {
    if (transactionType === 'cashout') return false
    return (
      pspProvider === 'itau_anybank' ||
      pspProvider === 'original' ||
      pspProvider === 'tbanks'
    )
  }

  const handleTransactionTypeTags = (transactionType: string | null) => {
    if (!transactionType) {
      return null
    }

    if (transactionType === 'cashin') {
      return (
        <>
          <s.Icon name="cashinarrowdown" fill="gray1" />
          <s.Text type="tag" fontWeight={400} color="graytheme6">
            cobrança
          </s.Text>
        </>
      )
    }
    if (transactionType === 'cashout') {
      return (
        <>
          <s.Icon name="cashoutarrowup" fill="gray1" />
          <s.Text type="tag" fontWeight={400} color="graytheme6">
            Envio
          </s.Text>
        </>
      )
    }
    if (transactionType === 'hybrid') {
      return (
        <s.TransactionTagWrapper>
          <s.Icon name="cashinarrowdown" fill="gray1" />
          <s.Text type="tag" fontWeight={400} color="graytheme6">
            cobrança
          </s.Text>
          <s.Text margin="0px 2px 0px 2px">+</s.Text>
          <s.Icon name="cashoutarrowup" fill="gray1" />
          <s.Text type="tag" fontWeight={400} color="graytheme6">
            envio
          </s.Text>
        </s.TransactionTagWrapper>
      )
    }
  }

  const handleAnybankTag = (
    pspProvider: string,
    transactionType: string | null
  ) => {
    if (isPspProviderAnybank(pspProvider, transactionType)) {
      return (
        <>
          <s.TextTag type="tag" fontWeight={400} color="graytheme6">
            receba em qualquer banco
          </s.TextTag>
        </>
      )
    }
    return <></>
  }

  return (
    <s.Section data-testid="first-section">
      <s.ProviderLine>
        <s.Text type="headline" color="graytheme6">
          Provedor
        </s.Text>
        <s.ProviderWrapper data-testid="provider-walletSettingInfo">
          <img width={34} src={wallet_icon} />
          <s.Text type="headline" fontWeight={400} color="graytheme6">
            {wallet_friendly_name}
          </s.Text>
        </s.ProviderWrapper>
      </s.ProviderLine>
      <s.SectionLine>
        <s.Text type="headline" color="graytheme6">
          Modalidade
        </s.Text>
        <s.TagWrapper>
          <s.TransactionTypeTag>
            {handleTransactionTypeTags(wallet_setting_transaction_type)}
          </s.TransactionTypeTag>
          <s.AnybankTag>
            {handleAnybankTag(pspProvider, wallet_setting_transaction_type)}
          </s.AnybankTag>
        </s.TagWrapper>
      </s.SectionLine>
      <s.SectionLine>
        <s.Text type="headline" color="graytheme6">
          Tarifa por transação
        </s.Text>
        <s.InputText
          width={304}
          value={
            split_fee_type == 'percentage' && fee_value
              ? String(fee_value + '%')
              : fee_value
              ? Number(fee_value).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
              : ''
          }
          disabled
        />
      </s.SectionLine>
      {split_fee_type == 'percentage' && (
        <s.SectionLine>
          <s.Align>
            <s.Text margin="0 0 8px 0" type="headline">
              Tarifa Mínima
            </s.Text>
            <s.Hover>
              <s.Icon fill="maincolor" name="questionmarkcircle" />
              <s.TooltipText>
                Caso o percentual da tarifa resulte em menos do que este valor,
                será cobrada a tarifa mínima. Sua tarifa mínima deverá ser
                superior ao Custo Plataforma Pix Itaú para continuar.
              </s.TooltipText>
            </s.Hover>
          </s.Align>
          <s.InputText
            width={304}
            value={
              minimum_fee
                ? Number(minimum_fee).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
                : ''
            }
            disabled
          />
        </s.SectionLine>
      )}
    </s.Section>
  )
}

export default FirstSection
