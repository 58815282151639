import styled, { css } from 'styled-components'
import TextCmp from 'components/Text'
import IconCmp from 'components/IconExporter'
import LoadingCmp from 'components/Loading'

export interface IOptionsWrapper {
  optionBoxDirection: 'top' | 'bottom'
  optionBoxSize: number
  hasLabel: boolean
}

interface IOptionsOpen {
  width?: number
  isOptionsOpen?: boolean
  disabled?: boolean
  error?: boolean | { icon?: string; message: string }
}

const inputErrorStyle = css`
  border: 2px solid ${(props) => props.theme.colors.lightred1};
  color: ${(props) => props.theme.colors.lightred1};
  :focus-within {
    border: 2px solid ${(props) => props.theme.colors.lightred1};
  }
`

export const SelectedItem = styled.div<IOptionsOpen>`
  ${({ disabled, isOptionsOpen, theme }) => {
    if (disabled) {
      return css`
        background-color: ${theme.colors.graytheme1};
      `
    }

    if (isOptionsOpen) {
      return css`
        background-color: ${theme.colors.graytheme3};
      `
    }

    if (!isOptionsOpen && !disabled) {
      return css`
        background-color: ${theme.colors.white};
      `
    }
  }}
  color: ${({ theme }) => theme.colors.maincolor};
  height: 40px;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(1)}px
    15px;
  border-radius: 8px;
  display: flex;
  box-shadow: ${({ isOptionsOpen, disabled, theme }) =>
    isOptionsOpen || disabled
      ? 'none'
      : theme.assets.inputComponent.inputTextShadow};
  align-items: center;
  ${(props) => props.error && inputErrorStyle}

  svg {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`

export const Container = styled.div<IOptionsOpen>`
  position: relative;
  width: ${(props) => props.width}px;
  :hover {
    ${SelectedItem} {
      box-shadow: ${({ isOptionsOpen, disabled, theme }) =>
        isOptionsOpen || disabled
          ? 'none'
          : theme.assets.inputComponent.inputTextShadow};
      background-color: ${({ disabled, theme }) =>
        disabled ? theme.colors.graytheme1 : theme.colors.white};
    }
  }

  :active {
    ${SelectedItem} {
      background-color: ${({ disabled, theme }) =>
        disabled ? theme.colors.graytheme1 : theme.colors.lightmaincolor2};
    }
  }
`

export const InputContainer = styled.div<{ disabled: boolean }>`
  position: relative;

  &:after {
    content: '';
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export const ArrowIcon = styled(IconCmp)`
  margin-left: auto;
`

export const Icon = styled(IconCmp)``

export const OptionsWrapper = styled.div<IOptionsWrapper>`
  position: absolute;
  width: 100%;
  z-index: 3;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.gray2};
  max-height: ${({ optionBoxSize }) => `${optionBoxSize}px`};
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.whiteshipay};
  ${({ optionBoxDirection, theme, hasLabel }) =>
    optionBoxDirection === 'top'
      ? `bottom: ${theme.spacing(5)}px`
      : `top: ${hasLabel ? theme.spacing(9) : theme.spacing(5)}px`};

  @media ${({ theme }) => theme.mediaQueries.sm} {
    max-height: 165px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.grid.breakpoints.sm}px) and (orientation: landscape) {
    max-height: 110px;
  }
`

interface IOptionsItem {
  selected?: boolean
}

export const OptionsItem = styled.div<IOptionsItem>`
  align-items: center;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.graytheme1 : theme.colors.whiteshipay};
  cursor: pointer;
  display: flex;
  height: 49px;
  padding: 0 ${({ theme }) => theme.spacing(3)}px 0 15px;
  transition: 0.15s background-color ease-in;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightmaincolor3};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.lightmaincolor2};
  }

  svg {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`

export const Overlay = styled.div`
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
`

export const TooltipText = styled(TextCmp)`
  width: 213px;
`

export const Text = styled(TextCmp)``

export const Loading = styled(LoadingCmp)``

export const LabelText = styled(TextCmp)`
  margin-bottom: 8px;
`

export const ErrorMessage = styled.span`
  bottom: 0;
  color: ${(props) => props.theme.colors.redshipay};
  display: flex;
  width: 100%;
`

export const ErrorIcon = styled(IconCmp)`
  fill: ${(props) => props.theme.colors.redshipay};
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`

export const TextError = styled(TextCmp).attrs(() => ({
  type: 'title',
}))`
  height: 10px;
  margin-bottom: 0.2em;
  font-size: 12px;
  font-weight: 400;
`
