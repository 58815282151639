import React, { Dispatch, SetStateAction, useState } from 'react'
import * as s from './styles'

interface FeaturesSelectionSectionProps {
  features: Set<string>
  pixProviderName: string
  walletIconUrl: string
  bankSlipSelected: boolean
  isLoading: boolean
  setBankSlipSelected: () => void
}

export const FeaturesSelectionSection: React.FC<
  FeaturesSelectionSectionProps
> = ({
  features,
  pixProviderName,
  walletIconUrl,
  isLoading,
  bankSlipSelected,
  setBankSlipSelected,
}) => {
  const [collapsedSeeMore, setCollapsedSeeMore] = useState({
    optionalFeatures: true,
    requiredFeatures: true,
  })

  return (
    <s.Sections data-testid="content-on-features-selection">
      <s.SectionsFeaturesSelectionWrapper
        showScroll={!collapsedSeeMore.optionalFeatures}
      >
        <s.Section>
          <s.ProviderContainer>
            <s.Text
              type="headline"
              margin="0 106px 0 0"
              fontWeight={600}
              color="graytheme8"
            >
              Provedor Pix
            </s.Text>
            <img
              src={walletIconUrl}
              alt={`${pixProviderName}-icon`}
              width={34}
            />
            <s.Text bold type="headline" margin="0 0 0 8px" color="graytheme8">
              {pixProviderName}
            </s.Text>
          </s.ProviderContainer>
        </s.Section>
        <s.Line />
        <s.Section>
          <s.Align>
            <s.Icon name="cycleiconpix" fill="graytheme6" />
            <s.Text
              margin="0 0 0 8px"
              type="headline"
              fontWeight={600}
              color="graytheme6"
            >
              Funcionalidades obrigatórias
            </s.Text>
          </s.Align>
          <s.SeeMoreWrapper>
            <s.Text margin="32px 0 0 0" bold type="headline" color="graytheme8">
              Receber Pix
            </s.Text>
            <s.Text margin="8px 0 8px 0" type="tag" color="graytheme8">
              Gere cobranças Pix por QR Code, ou Pix copia e cola, para receber
              em sua conta.{' '}
              <s.SeeMoreButton
                data-testid="see-more-button-required-feature"
                onClick={() =>
                  setCollapsedSeeMore({
                    ...collapsedSeeMore,
                    requiredFeatures: !collapsedSeeMore.requiredFeatures,
                  })
                }
              >
                {collapsedSeeMore.requiredFeatures ? 'ver mais' : 'ocultar'}
              </s.SeeMoreButton>
            </s.Text>
            {!collapsedSeeMore.requiredFeatures ? (
              <s.Text
                data-testid="see-more-required-text"
                fontWeight={400}
                type="paragraph"
                color="graytheme8"
              >
                Cadastre sua conta bancária para gerar cobranças Pix (Pix QR
                Code e Pix Copia e Cola) e receber pagamentos diretamente na sua
                conta. Consulte as tarifas com o time comercial da Shipay.
              </s.Text>
            ) : (
              <></>
            )}
          </s.SeeMoreWrapper>
        </s.Section>
        {features.has('bank_slip_settings') && (
          <>
            <s.Line />
            <s.Section>
              <s.Align>
                <s.Icon name="foursquare" fill="graytheme6" />
                <s.Text
                  margin="0 0 0 8px"
                  type="headline"
                  fontWeight={600}
                  color="graytheme6"
                >
                  Funcionalidades opcionais
                </s.Text>
              </s.Align>
              <s.RoundedSquareWrapper>
                <s.RoundedSquareContainer>
                  <s.SeeMoreContainer data-testid="bank-slip-feature">
                    <s.Text bold type="headline" color="graytheme8">
                      Boleto híbrido
                    </s.Text>
                    <s.Text margin="8px 0 0 0" type="tag" color="graytheme8">
                      Gere cobranças com Boleto e Pix ao mesmo tempo. Verifique
                      a disponibilidade no seu sistema.{' '}
                      <s.SeeMoreButton
                        data-testid="see-more-button-optional-feature"
                        onClick={() =>
                          setCollapsedSeeMore({
                            ...collapsedSeeMore,
                            optionalFeatures:
                              !collapsedSeeMore.optionalFeatures,
                          })
                        }
                      >
                        {collapsedSeeMore.optionalFeatures
                          ? 'ver mais'
                          : 'ocultar'}
                      </s.SeeMoreButton>
                    </s.Text>
                  </s.SeeMoreContainer>
                  {isLoading ? (
                    <s.Loading width={100} type="spinner" color="maincolor" />
                  ) : (
                    <s.AddCheckButton
                      checked={bankSlipSelected}
                      onClick={() => {
                        if (!isLoading) {
                          setBankSlipSelected()
                        }
                      }}
                    >
                      <s.CheckSquare>
                        <s.Icon name="checkmark" fill="white" />
                      </s.CheckSquare>
                      <s.Text
                        fontWeight={600}
                        type="headline"
                        color="maincolor"
                      >
                        {bankSlipSelected ? 'Adicionado' : 'Adicionar'}
                      </s.Text>
                    </s.AddCheckButton>
                  )}
                </s.RoundedSquareContainer>

                {!collapsedSeeMore.optionalFeatures ? (
                  <s.Text
                    data-testid="see-more-optional-text"
                    fontWeight={400}
                    type="paragraph"
                    color="graytheme8"
                    margin="16px 0 0 0"
                  >
                    Registre boletos híbridos para cobrar seus clientes com um
                    instrumento de cobrança que contém tanto um boleto, quanto
                    um QR Code Pix. Receba direto na sua conta bancária
                    cadastrada na Shipay. Caso o instrumento seja pago com Pix,
                    o boleto é cancelado e vice-versa.
                  </s.Text>
                ) : (
                  <></>
                )}
              </s.RoundedSquareWrapper>
            </s.Section>
          </>
        )}
      </s.SectionsFeaturesSelectionWrapper>
    </s.Sections>
  )
}
