import styled, { css } from 'styled-components'
import ModalCmp from 'components/Modal'
import IconCmp from 'components/IconExporter'
import TextCmp from 'components/Text'
import ButtonCmp from 'components/Button'
import InputTextCmp from 'components/Inputs/Text'
import { ModalContainer, ChildrenWrapper } from 'components/Modal/styles'
import LoadingCmp from 'components/Loading'
import SelectCmp from 'components/Inputs/Select'
import RadioComponentCmp from 'components/Inputs/RadioButton'

export const Modal = styled(ModalCmp)`
  header {
    background: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
    padding: 32px;
    h5 {
      color: ${({ theme }) => theme.colors.graytheme6};
    }
  }

  ${ModalContainer} {
    padding: 0px;
  }

  ${ChildrenWrapper} {
    padding: 0px;
    overflow-y: hidden;
  }
`

export const ModalContent = styled.div`
  position: relative;
  width: 584px;
  height: 540px;
  margin: 0px 32px 32px 32px;
`

export const LoadingGradient = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 134px;
  img {
    will-change: transform;
    transition-property: transform;
    animation-name: rotate;
    animation-duration: 1100ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  @media screen and (max-width: 1440px) {
    margin-top: 84px;
  }
`

export const Text = styled(TextCmp)``

export const SucessWrapper = styled.div`
  overflow: hidden;
  padding-top: 32px;
  img {
    padding-top: 88px;
  }

  @media screen and (max-width: 1440px) {
    img {
      padding: 68px 0px 32px 0px;
      width: 95%;
    }
  }
`

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-width: 584px;
  padding-right: 32px;
  ::-webkit-scrollbar-track {
    margin-top: 32px;
    margin-bottom: 42px;
  }

  height: calc(100% - 75px);
`

export const SectionFormWrapper = styled.div`
  padding: 0px 4px;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0px;
`

export const Line = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`

interface ButtonWrapperProps {
  margin?: string
}

export const Div = styled.div``

export const PreviousNextButtonContainer = styled.div<ButtonWrapperProps>`
  position: absolute;
  width: 100%;
  bottom: 0;
  background: white;

  div:first-child {
    width: calc(100%+32px);
    height: 1px;
    margin: 0px -32px 32px -32px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
  }
`

export const PreviousNextButtonWrapper = styled.div<ButtonWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin: ${({ margin }) => margin};
`

export const Button = styled(ButtonCmp)`
  width: 100%;
  box-shadow: 0px 1px 4px #b3b3b3;
  svg {
    margin-right: 8px;
  }
`

export const Icon = styled(IconCmp)`
  display: inline;
`

export const Loading = styled(LoadingCmp)``

export const LoadingWrapper = styled.div`
  margin-top: 32px;
  overflow-y: hidden;
`

export const SectionLine = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 40px;
  margin-top: 32px;

  :first-child {
    margin-top: 0px;
  }
`

export const InputText = styled(InputTextCmp)``

export const Select = styled(SelectCmp)`
  width: 260px;
  p {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.graytheme6};
  }
`

export const InfoBox = styled.div<{
  error: boolean
}>`
  display: flex;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.graytheme2};
  margin-bottom: 32px;
  ${({ error, theme }) =>
    error
      ? css`
          background-color: ${theme.colors.cancelledStatusBackground};
          border: 0;
          flex-direction: column;
        `
      : css``}
`

export const SuccessButtonWrapper = styled.div<{ margin?: string }>`
  min-height: 72px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: stretch;
  align-content: space-between;
  justify-content: space-between;
  gap: 16px;
  ::before {
    content: '';
    right: 0;
    width: 100%;
    position: absolute;
    height: 1px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme3};
  }
  > div {
    margin-top: 28px;
    width: 100%;
  }
`

export const SuccessWrapper = styled.div`
  /* flex-grow: 1;
  overflow: auto;
  ::-webkit-scrollbar-track {
    margin-bottom: 32px;
  }
  padding-right: 16px; */
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100% - 75px);
  gap: 16px;
  ::-webkit-scrollbar-track {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`

export const ComissionType = styled.div`
  display: flex;
  gap: 16px;
`

export const RadioButton = styled(RadioComponentCmp)``

export const RadioWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 120px;
  height: 40px;

  border-radius: 8px;

  box-shadow: ${({ disabled, theme }) =>
    disabled ? 'none' : theme.assets.inputComponent.inputTextShadow};
`

export const TooltipText = styled.span`
  visibility: hidden;
  width: 300px;
  background-color: ${({ theme }) => theme.colors.graytheme6};
  color: white;
  max-height: 96px;
  font-size: 14px;
  text-align: center;
  border-radius: 6px;
  padding: 8px 8px;
  position: absolute;
  z-index: 10;
  bottom: -24px;
`

export const Hover = styled.div`
  position: relative;
  :hover {
    cursor: help;
    ${TooltipText} {
      visibility: visible;
    }
  }
`

export const Align = styled.div`
  display: flex;
  align-items: baseline;
`

interface ImageWrapperProps {
  width?: number
  height?: number
}

export const ImageErrorWrapper = styled.div<ImageWrapperProps>`
  margin: auto auto 16px auto;

  img {
    width: ${({ width }) => (width ? `${width}px` : '491px')};
    height: ${({ height }) => (height ? `${height}px` : '248px')};
  }
`

export const ImageWrapper = styled.div<ImageWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  /* margin: 54px auto 0px auto; */

  @media screen and (max-width: 1440px) {
    margin: 16px auto 0px auto;
  }
  img {
    width: ${({ width }) => (width ? `${width}px` : '491px')};
    height: ${({ height }) => (height ? `${height}px` : '248px')};
  }
`
