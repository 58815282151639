import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  PartnerWalletSettingsStatus,
  WallletSettingInfo,
} from '../EditItauAnybank'
import { useGetPixWS } from 'hooks/pixWS'
import { ISplit } from 'pages/FeeSplitterManagement/types'
import pixWSService from 'services/pixWalletSettings'
import { useNonInitialEffect } from 'hooks/utils'
import {
  baseURLPixV1PartnerWalletSettings,
  baseURLRegistrationSummarySplitFee,
  get,
  patch,
} from 'services/api'

import { showToast } from 'components/Toast'
import * as s from '../styles'
import SplitFeeManager from 'pages/FeeSplitterManagement/Management/components/SplitFeeManager'
import { SelectToggle } from './SelectToggle'

interface FourthSectionProps
  extends Pick<WallletSettingInfo, 'more_details' | 'wallet_setting_uuid'> {
  certList: Array<{ id: string; text: string }>
  currentWalletSettingInfo: WallletSettingInfo
  updateWalletSettingInfo: Dispatch<SetStateAction<WallletSettingInfo>>
}

const FourthSection: React.FC<FourthSectionProps> = ({
  more_details,
  wallet_setting_uuid,
  certList,
  currentWalletSettingInfo,
  updateWalletSettingInfo,
}) => {
  const [enableEdit, setEnableEdit] = useState<boolean>(false)
  const [walletPixDictKey, setWalletPixDictKey] = useState<string>(
    more_details.wallet_setting_dict_key
  )

  const [selectedCert, setSelectedCert] = useState({ id: '', name: '' })
  const [certSelectList, setCertSelectList] = useState([])
  const [customSplitsModal, setCustomSplitsModal] = useState(false)
  const { pixWS, loadPixWS } = useGetPixWS()
  const [splitFee, setSplitFee] = useState<ISplit | {}>({})
  const [hasSplitFee, setHasSplitFee] = useState<boolean>(false)
  const [patchIsLoading, setPatchIsLoading] = useState<boolean>(false)
  const [
    hasErrorOnPartnerStatusUpdateChange,
    setHasErrorOnPartnerStatusUpdateChange,
  ] = useState<boolean>(false)
  const [partnerWalletSettingsStatus, setPartnerWalletSettingsStatus] =
    useState<PartnerWalletSettingsStatus>(
      more_details.partner_wallet_settings_status
    )

  interface PatchProps {
    dict_key?: string | null
    tls_certificate?: string | null
  }

  let payload = {} as PatchProps

  useEffect(() => {
    if (certList.length > 0) {
      const _list = certList.map((x) => {
        return {
          ...x,
          onClick: () => {
            setSelectedCert({ id: x.id, name: x.text })
          },
        }
      })
      setCertSelectList(_list)
      if (more_details.tls_certificate) {
        const c = certList.find((x) => x.id === more_details.tls_certificate)
        if (c) setSelectedCert({ id: c.id, name: c.text })
      }
    }
  }, [certList])

  useEffect(() => {
    if (wallet_setting_uuid && !customSplitsModal)
      loadPixWS(wallet_setting_uuid)
  }, [wallet_setting_uuid, customSplitsModal])

  useEffect(() => {
    if (splitFee) {
      const { minimum_fee, split_fee_value } = splitFee as ISplit
      updateWalletSettingInfo({
        ...currentWalletSettingInfo,
        minimum_fee: minimum_fee,
        fee_value: split_fee_value,
      })
    }
  }, [splitFee])

  const handleAccountStatusChange = useCallback(
    (active: boolean) => {
      setPatchIsLoading(true)
      patch(
        `${baseURLPixV1PartnerWalletSettings}/${wallet_setting_uuid}/status`,
        {
          active,
        }
      )
        .then(() => {
          setPartnerWalletSettingsStatus(active ? 'active' : 'inactive')
        })
        .catch(() => {
          setPartnerWalletSettingsStatus(
            more_details.partner_wallet_settings_status
          )
          setHasErrorOnPartnerStatusUpdateChange(true)
        })
        .finally(() => {
          setPatchIsLoading(false)
        })
    },
    [wallet_setting_uuid, more_details.partner_wallet_settings_status]
  )

  const renderAccountStatus = useCallback(() => {
    switch (partnerWalletSettingsStatus) {
      case 'active':
        return (
          <s.SectionLine>
            <s.ActivationWrapper data-testid="active-status-test">
              <s.Text type="headline" color="graytheme6">
                Funcionamento
              </s.Text>
              <s.Button
                color="whitedetailed"
                disabled={patchIsLoading}
                data-testid="deactivate-button-test"
                onClick={() => handleAccountStatusChange(false)}
              >
                {patchIsLoading ? (
                  <s.Loading type="spinner" color="white" />
                ) : (
                  <s.Icon name="pausecircle" fill="redshipay" />
                )}
                <s.Text type="headline" fontWeight={600} color="redshipay">
                  Inativar conta
                </s.Text>
              </s.Button>
            </s.ActivationWrapper>
          </s.SectionLine>
        )
      case 'inactive':
        return (
          <s.SectionLine>
            <s.ActivationWrapper data-testid="inactive-status-test">
              <s.Text type="headline" color="graytheme6">
                Funcionamento
              </s.Text>
              <s.Button
                data-testid="activate-button-test"
                disabled={patchIsLoading}
                onClick={() => handleAccountStatusChange(true)}
              >
                {patchIsLoading ? (
                  <s.Loading type="spinner" color="white" />
                ) : (
                  <s.Icon name="playcircle" fill="white" />
                )}
                <s.Text type="headline" fontWeight={600} color="white">
                  Reativar conta
                </s.Text>
              </s.Button>
            </s.ActivationWrapper>
          </s.SectionLine>
        )
      case 'error':
        return (
          <s.SectionLine>
            <s.ActivationWrapper data-testid="error-status-test">
              <s.Text type="headline" color="graytheme6">
                Funcionamento
              </s.Text>
              <s.Button data-testid="error-status-button-test" disabled>
                {patchIsLoading ? (
                  <s.Loading type="spinner" color="white" />
                ) : (
                  <s.Icon name="pausecircle" fill="white" />
                )}
                <s.Text type="headline" fontWeight={600} color="white">
                  Indisponível
                </s.Text>
              </s.Button>
            </s.ActivationWrapper>
          </s.SectionLine>
        )
      default:
        return null
    }
  }, [partnerWalletSettingsStatus, patchIsLoading, handleAccountStatusChange])

  const handleUpdate = async () => {
    if (more_details.tls_certificate && more_details.wallet_setting_dict_key) {
      if (more_details.tls_certificate != selectedCert.id) {
        Object.assign(payload, { tls_certificate: selectedCert.id })
      }

      if (more_details.wallet_setting_dict_key != walletPixDictKey) {
        Object.assign(payload, { dict_key: walletPixDictKey })
      }
    }

    const handleSuccessMessage = (payload: PatchProps): string => {
      if ('dict_key' in payload && 'tls_certificate' in payload)
        return 'Chave Pix e Certificado alterados com sucesso!'
      if ('dict_key' in payload && !('tls_certificate' in payload))
        return 'Chave Pix alterada com sucesso!'
      return 'Certificado alterado com sucesso!'
    }

    setPatchIsLoading(true)
    await pixWSService
      .editPixWS(wallet_setting_uuid, payload)
      .then((response) => {
        updateWalletSettingInfo({
          ...currentWalletSettingInfo,
          more_details: {
            tls_certificate: selectedCert.id,
            wallet_setting_dict_key: walletPixDictKey,
          } as Pick<
            WallletSettingInfo['more_details'],
            'tls_certificate' | 'wallet_setting_dict_key'
          >,
        })
        showToast({
          type: 'success',
          message: handleSuccessMessage(payload),
        })
      })
      .catch((e) => {
        resetData()
        showToast({
          type: 'error',
          message: e.response.data?.detail || e.response.data?.message,
        })
      })
      .finally(() => {
        setPatchIsLoading(false)
      })
  }

  const resetData = () => {
    setWalletPixDictKey(more_details.wallet_setting_dict_key)
    const c = certList.find((x) => x.id === more_details.tls_certificate)
    if (c) setSelectedCert({ id: c.id, name: c.text })
  }

  useNonInitialEffect(() => {
    if (pixWS.tls_certificate) {
      const c = certSelectList.find((x) => x.id === pixWS.tls_certificate)
      if (c) setSelectedCert({ id: c.id, name: c.text })
    }
    checkIfHasSplitFee()
  }, [pixWS])

  const checkIfHasSplitFee = async () => {
    await get(`${baseURLRegistrationSummarySplitFee}/${pixWS.split_fee_id}`)
      .then((res) => {
        if (res.data.split_fee_id) {
          setSplitFee(res.data)
          setHasSplitFee(true)
        } else {
          setHasSplitFee(false)
        }
      })
      .catch((e) => {
        setHasSplitFee(false)
        if (e.request.status !== 404 && e.request.status !== 403) {
          showToast({
            type: 'error',
            message: JSON.parse(e.request.response).detail,
          })
        }
      })
  }

  const customSplitModal = () => {
    return (
      <>
        {customSplitsModal ? (
          <s.ModalSplits
            handleClose={() => {
              setCustomSplitsModal(false)
            }}
            isOpen={customSplitsModal}
            modalSubtitle="Configurar Tarifa Customizada"
          >
            <s.ModalContentSplits>
              <s.Text>
                Tarifa customizada que será descontada desta conta Pix por
                transação liquidada.
              </s.Text>
              {hasSplitFee ? (
                <SplitFeeManager
                  handleClose={() => {
                    setCustomSplitsModal(false)
                  }}
                  walletSettingId={wallet_setting_uuid}
                  isDefaultSplitFee={pixWS.is_default_split_fee}
                  splitFee={splitFee as ISplit}
                  isCustomFee={true}
                />
              ) : (
                <></>
              )}
            </s.ModalContentSplits>
          </s.ModalSplits>
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <>
      {customSplitModal()}
      <s.Section data-testid="fourth-section">
        <s.SectionHeader>
          <s.TitleWrapper>
            <s.Icon name="gear" fill="lightred1" />
            <s.Text type="headline" color="lightred1">
              Mais Detalhes
            </s.Text>
          </s.TitleWrapper>
          {!enableEdit ? (
            <s.Button
              onClick={() => setEnableEdit(!enableEdit)}
              width="98px"
              color="white"
              outline={false}
            >
              <s.Icon name="pencil" fill="maincolor" />
              <s.Text color="maincolor" type="headline" fontWeight={600}>
                Editar
              </s.Text>
            </s.Button>
          ) : (
            <></>
          )}
        </s.SectionHeader>
        {(partnerWalletSettingsStatus === 'error' ||
          hasErrorOnPartnerStatusUpdateChange) && (
          <s.InfoBox
            data-testid="info-box-error"
            margin="-16px 0px 0px 0px"
            error
          >
            <s.Text bold type="paragraph" color="redshipay">
              {hasErrorOnPartnerStatusUpdateChange
                ? 'Erro ao atualizar'
                : 'Serviço “Funcionamento” indisponível'}
            </s.Text>
            <s.Text type="paragraph" color="cancelledStatusTextColor">
              {hasErrorOnPartnerStatusUpdateChange
                ? `Não foi possível ${
                    partnerWalletSettingsStatus === 'active'
                      ? 'inativar'
                      : 'reativar'
                  } a conta do Seller no momento. Por favor, tente novamente mais tarde.`
                : 'Não foi possível consultar o status do Seller no momento. Por favor, tente novamente mais tarde.'}
            </s.Text>
          </s.InfoBox>
        )}
        <s.SectionLine>
          <s.IdContainer>
            <s.Text type="headline" color="graytheme6">
              ID
            </s.Text>
            <s.IdWrapper
              onClick={(e) => {
                e.stopPropagation()
                navigator.clipboard.writeText(wallet_setting_uuid as string)
                showToast({ type: 'success', message: 'ID Copiado!' })
              }}
            >
              {wallet_setting_uuid}
              <s.Icon
                width={24}
                height={24}
                fill="maincolor"
                name="clipboard"
              />
            </s.IdWrapper>
          </s.IdContainer>
        </s.SectionLine>
        <div></div>
        <s.SectionLine>
          <s.Text type="headline" color="graytheme6">
            Chave Pix de Passagem
          </s.Text>
          <s.InputText
            width="304"
            testId="input-dict-key"
            error={!walletPixDictKey ? { message: 'campo obrigatório' } : false}
            value={walletPixDictKey}
            onChange={(e) => setWalletPixDictKey(e.target.value)}
            disabled={!enableEdit || patchIsLoading}
          />
        </s.SectionLine>
        <s.SectionLine>
          <s.Text type="headline" color="graytheme6">
            Certificado TLS
          </s.Text>
          <SelectToggle
            options={certSelectList}
            selected={selectedCert}
            disabled={!enableEdit || patchIsLoading}
          />
        </s.SectionLine>
        <s.SectionLine>
          <s.EditFeeWraper>
            <s.Text type="headline" color="graytheme6">
              Configuração de tarifa
            </s.Text>
            <s.Button
              disabled={patchIsLoading}
              onClick={() => setCustomSplitsModal(true)}
            >
              {patchIsLoading ? (
                <s.Loading type="spinner" color="white" />
              ) : (
                <s.Icon name="payments" fill="white" />
              )}
              <s.Text type="headline" fontWeight={600} color="white">
                Editar tarifa
              </s.Text>
            </s.Button>
          </s.EditFeeWraper>
        </s.SectionLine>
        {renderAccountStatus()}
        {enableEdit ? (
          <s.SectionLine>
            <div></div>
            <s.ButtonWrapper>
              <s.CancelButton
                color="white"
                data-testid="cancel-button-second-section"
                disabled={patchIsLoading}
                onClick={() => {
                  setEnableEdit(false)
                  resetData()
                }}
              >
                <s.Text type="headline" fontWeight={600} color="maincolor">
                  Cancelar
                </s.Text>
              </s.CancelButton>
              <s.Button
                data-testid="save-button-second-section"
                disabled={
                  patchIsLoading ||
                  (selectedCert.id === more_details.tls_certificate &&
                    walletPixDictKey ===
                      more_details.wallet_setting_dict_key) ||
                  !walletPixDictKey ||
                  !selectedCert.id
                }
                onClick={() => handleUpdate()}
              >
                {patchIsLoading ? (
                  <s.Loading type="spinner" color="white" />
                ) : (
                  <s.Icon name="pencilUnderline" fill="white" />
                )}
                <s.Text type="headline" fontWeight={600} color="white">
                  Salvar edição
                </s.Text>
              </s.Button>
            </s.ButtonWrapper>
          </s.SectionLine>
        ) : (
          <></>
        )}
      </s.Section>
    </>
  )
}

export default FourthSection
