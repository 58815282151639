import { Dispatch, SetStateAction, useState } from 'react'
import { WallletSettingInfo } from '../EditItauAnybank'
import pixWSService from 'services/pixWalletSettings'
import { showToast } from 'components/Toast'

import * as s from '../styles'

interface SecondSectionProps
  extends Pick<
    WallletSettingInfo,
    'pix_account_details' | 'wallet_setting_uuid'
  > {
  currentWalletSettingInfo: WallletSettingInfo
  updateWalletSettingInfo: Dispatch<SetStateAction<WallletSettingInfo>>
}

const SecondSection: React.FC<SecondSectionProps> = ({
  pix_account_details,
  wallet_setting_uuid,
  currentWalletSettingInfo,
  updateWalletSettingInfo,
}) => {
  const [enableEdit, setEnableEdit] = useState<boolean>(false)
  const [patchIsLoading, setPatchIsLoading] = useState<boolean>(false)
  const [walletName, setWalletName] = useState<string>(
    pix_account_details.wallet_setting_name
  )

  const handleUpdate = async () => {
    setPatchIsLoading(true)
    await pixWSService
      .editPixWS(wallet_setting_uuid, { name: walletName })
      .then((response) => {
        updateWalletSettingInfo({
          ...currentWalletSettingInfo,
          pix_account_details: {
            wallet_setting_name: walletName,
          },
        })
        showToast({
          type: 'success',
          message: 'Apelido alterado com sucesso',
        })
      })
      .catch((e) => {
        setWalletName(pix_account_details.wallet_setting_name)
        showToast({
          type: 'error',
          message: 'Problema ao editar apelido',
        })
      })
      .finally(() => {
        setPatchIsLoading(false)
      })
  }

  return (
    <s.Section data-testid="second-section">
      <s.SectionHeader>
        <s.TitleWrapper>
          <s.Icon name="accountwallet" fill="gray1" />
          <s.Text type="headline" color="graytheme6">
            Detalhes da conta Pix
          </s.Text>
        </s.TitleWrapper>
        {!enableEdit ? (
          <s.Button
            onClick={() => setEnableEdit(!enableEdit)}
            width="98px"
            color="white"
            data-testid="edit-button-second-section"
          >
            <s.Icon name="pencil" fill="maincolor" />
            <s.Text color="maincolor" type="headline" fontWeight={600}>
              Editar
            </s.Text>
          </s.Button>
        ) : (
          <></>
        )}
      </s.SectionHeader>
      <s.SectionLine>
        <s.Text type="headline" color="graytheme6">
          Apelido
        </s.Text>
        <s.InputText
          width="304"
          testId="input-apelido"
          error={!walletName ? { message: 'campo obrigatório' } : false}
          value={walletName}
          onChange={(e) => setWalletName(e.target.value)}
          disabled={!enableEdit || patchIsLoading}
        />
      </s.SectionLine>
      {enableEdit ? (
        <s.SectionLine>
          <div></div>
          <s.ButtonWrapper>
            <s.CancelButton
              color="white"
              data-testid="cancel-button-second-section"
              disabled={patchIsLoading}
              onClick={() => {
                setEnableEdit(false)
                setWalletName(pix_account_details.wallet_setting_name)
              }}
            >
              <s.Text type="headline" fontWeight={600} color="maincolor">
                Cancelar
              </s.Text>
            </s.CancelButton>
            <s.Button
              data-testid="save-button-second-section"
              disabled={
                patchIsLoading ||
                pix_account_details.wallet_setting_name === walletName ||
                !walletName
              }
              onClick={() => handleUpdate()}
            >
              {patchIsLoading ? (
                <s.Loading type="spinner" color="white" />
              ) : (
                <s.Icon name="pencilUnderline" fill="white" />
              )}
              <s.Text type="headline" fontWeight={600} color="white">
                Salvar edição
              </s.Text>
            </s.Button>
          </s.ButtonWrapper>
        </s.SectionLine>
      ) : (
        <></>
      )}
    </s.Section>
  )
}

export default SecondSection
