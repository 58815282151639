import React from 'react'
import * as s from './styles'

import erroranybank from 'assets/images/anybank-register-error.png'

export default function Error() {
  return (
    <s.Sections data-testid="content-on-loading">
      <s.Text margin="32px 0 16px 0" type="heading4" bold color="graytheme6">
        Erro na definição da tarifa
      </s.Text>
      <s.Text type="headline" color="graytheme6">
        Um erro aconteceu, tente novamente mais tarde. Caso o problema persista,
        entre em contato com o suporte.
      </s.Text>
      <s.ImageErrorWrapper>
        <img src={erroranybank} alt="Imagem de Erro Pix AnyBank" />
      </s.ImageErrorWrapper>
    </s.Sections>
  )
}
