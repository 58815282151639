import styled, { css } from 'styled-components'

import ButtonCmp from 'components/Button'
import TextCmp from 'components/Text'
import IconCmp from 'components/IconExporter'
import LogoCmp from 'components/LogoExporter'
import ModalCmp from 'components/Modal'
import { OptionWrapper } from 'components/Table/styles'

export const Modal = styled(ModalCmp)``

export const ConfirmationModal = styled(ModalCmp)`
  z-index: 14;
`

export const LogoExporter = styled(LogoCmp)``

export const BlueVector1 = styled.div`
  z-index: -1;
  position: absolute;
  left: 1.21%;
  right: 38.66%;
  top: -6.86%;
  bottom: -21.44%;
  height: 100vh;
  width: 900px;
  background: ${({ theme }) => theme.assets.header.background1};
`

export const BlueVector2 = styled.div`
  z-index: -1;
  position: absolute;
  left: 52.93%;
  right: -2.97%;
  height: 100vh;
  background: ${({ theme }) => theme.assets.header.background2};
`

export const Icon = styled(IconCmp)``

export const NavbarContainer = styled.div`
  background: rgba(255, 255, 255, 0.75);
  transition: width 0.8s;
  height: 100px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  border-bottom: ${({ theme }) => theme.assets.header.borderBottom};
  box-shadow: 0px 3px 6px #00000029;
`

export const ShipayLogo = styled.div`
  padding: ${({ theme }) => theme.spacing(4)}px;
  > svg {
    margin-right: 8px;
  }
`

export const List = styled.ul``

interface IListItem {
  isSelected?: boolean
}

export const ListItem = styled.li<IListItem>`
  list-style-type: none;
  display: inline-block;
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin: ${({ theme }) => theme.spacing(2)}px;
  border-radius: 8px;
  ${(props) =>
    props.isSelected
      ? css`
          ${({ theme }) => theme.assets.header.listItem.selected};
        `
      : css``}
  :hover {
    ${({ theme }) => theme.assets.header.listItemHover};
  }
  > a > h5 {
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      margin-right: 8px;
    }
  }
`

export const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const Button = styled(ButtonCmp)``

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: ${({ theme }) => theme.spacing(2)}px
    ${({ theme }) => theme.spacing(4)}px ${({ theme }) => theme.spacing(2)}px
    ${({ theme }) => theme.spacing(2)}px;
`

interface IUserWrapper {
  isActive?: boolean
}

export const UserImage = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50%;
`

export const Text = styled(TextCmp)``

export const UserText = styled(TextCmp)`
  white-space: nowrap;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SiteBlindadoAnchor = styled.a`
  position: relative;
  background: url('https://seal.siteblindado.com.br/painel.shipay.com.br/seal.png')
    no-repeat;
  width: 120px;
  height: 50px;
  right: 0;
  bottom: 0px;
  top: 20px;
  margin: 0 auto;
  cursor: pointer;
  display: block;
  text-decoration: none;
`

export const OptionsWrapper = styled.div`
  position: absolute;
  width: 217px;
  z-index: 3;
  border-radius: 8px;
  display: none;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.shadows.gray2};
  max-height: 200px;
  overflow-y: auto;
  left: 0px;
  background-color: ${({ theme }) => theme.colors.whiteshipay};
  top: ${({ theme }) => theme.spacing(5.1)}px;
  @media ${({ theme }) => theme.mediaQueries.sm} {
    max-height: 165px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.grid.breakpoints.sm}px) and (orientation: landscape) {
    max-height: 110px;
  }
`

export const UserOptionsWrapper = styled(OptionsWrapper)`
  width: 173px;
`

interface IOptionsItem {
  selected?: boolean
}

export const OptionsItem = styled.div<IOptionsItem>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.graytheme1};
  cursor: pointer;
  display: flex;
  height: 40px;
  padding: 0 ${({ theme }) => theme.spacing(3)}px;
  text-transform: capitalize;
  width: 100%;
  transition: 0.15s background-color ease-in;
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme2};
  }
  > svg {
    margin-left: ${({ theme }) => theme.spacing(1)}px;
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.graytheme3};
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.graytheme2};
  }
`

export const UserWrapper = styled.div<IUserWrapper>`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 8px;
  > * {
    margin-right: 8px;
  }
  :hover {
    cursor: pointer;
    box-shadow: 0px 1px 8px 3px rgba(92, 170, 229, 0.5);
    background: ${({ theme }) => theme.colors.whiteshipay};
    border-radius: 8px;
  }
  :hover ${OptionsWrapper} {
    display: flex;
  }
`

export const MoreOptionsWrapper = styled.div<IUserWrapper>`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 8px;
  > * {
    margin-right: 8px;
  }
  :hover {
    cursor: pointer;
    box-shadow: 0px 1px 8px 3px rgba(92, 170, 229, 0.5);
    background: ${({ theme }) => theme.colors.whiteshipay};
    border-radius: 8px;

    * > svg {
      fill: ${({ theme }) => theme.colors.graytheme6};
    }

    ${OptionsWrapper} {
      display: flex;
    }
  }
`
export const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.graytheme2};
  position: absolute;
  left: 0;
  width: 100%;
  z-index: -1;
`

export const InputUpload = styled.input`
  display: none;
`
export const UploadContent = styled.label`
  border: 1px solid #ccc;
  display: flex;
  padding: 6px 12px;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  margin-horizontal: 34px;
  width: 100%;
  margin-top: 16px;
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  padding-top: 16px;
`
export const TextParagraph = styled(TextCmp)`
  margin-right: 16px;
`
export const TextFile = styled(TextCmp)`
  max-width: 50%;
  padding-bottom: 20px;
  size: 18px;
`
export const TextFailFile = styled(TextCmp)`
  margin: 20px 0px 20px 0px;
  size: 18px;
  text-align: center;
  color: red;
`
export const Space = styled.div`
  margin-bottom: 30px;
`
export const ButtonModal = styled(ButtonCmp)`
  display: block;
  margin: 0 auto;
  margin-bottom: 24px;
  width: 100%;
  margin-horizontal: 34px;
  height: 40px;
  > span {
    text-transform: none;
  }
`

export const DeleteLogoButton = styled(ButtonCmp)`
  margin-top: 24px;
  height: 40px;

  * > svg {
    margin-right: 8px;
  }
`

export const RulesContain = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`

export const Align = styled.div`
  display: flex;
  align-items: stretch;
`
