import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { getValueFromLocalStorage } from 'helpers/localStorage'
import { logout } from 'services/auth'

import dict from 'components/IconExporter'

import * as s from './styles'
import { EditUserModal } from 'pages/NavbarModals/EditUserModal'
import { checkUserClaim } from 'helpers/claims'
import { PrePaymentModal } from 'pages/NavbarModals/PrePayment'
import { DefaultSplitFeeModal } from 'pages/NavbarModals/DefaultSplitFeeModal'
import UploadFileModal from './UploadFileModal'
import { ConciliationTasksModal } from 'pages/NavbarModals/conciliationTasks/ConciliataionTasksModal'
import { isSpacePay } from 'helpers'
import { UserSessionInfo } from 'store/modules/auth/types'
import useImpersonate from 'hooks/useImpersonate'

interface IProps {
  menuItems: any
}

const Navbar: React.FC<IProps> = ({ menuItems }) => {
  const location = useLocation()
  const currentUserSession: UserSessionInfo = JSON.parse(
    getValueFromLocalStorage('currentUserSession')
  )
  const isLoggedIn = currentUserSession && currentUserSession.id
  const [editUserModal, setEditUserModal] = React.useState<string>('')
  const [antecipationModal, setAntecipationModal] = React.useState<string>('')
  const [defaultSplitFeeModal, setDefaultSplitFeeModal] =
    React.useState<boolean>(false)
  const [changeLogoModal, setChangeLogoModal] = React.useState<boolean>(false)
  const [conciliationTasksModal, setConciliationTasksModal] =
    React.useState<boolean>(false)
  const history = useHistory()
  const { removeImpersonate } = useImpersonate()

  const options = [
    {
      id: '1',
      onClick: () => {},
      icon: 'questionmarkcircle',
      text: 'Ajuda',
    },
    {
      id: '2',
      onClick: () => {
        setEditUserModal('true')
      },
      icon: 'pencil',
      text: 'Editar',
    },
    {
      id: '3',
      onClick: () => {
        logout()
      },
      icon: 'exit',
      text: 'Sair',
    },
  ]

  const moreOptions = [
    {
      id: '1',
      onClick: () => {
        setAntecipationModal('true')
      },
      icon: 'clock',
      text: 'Antecipação',
    },

    {
      id: '2',
      onClick: () => {
        setChangeLogoModal(true)
      },
      icon: 'upload',
      text: 'Configurar logo PDV',
    },
    {
      id: '3',
      onClick: () => {
        history.push('/wl-settings')
      },
      icon: 'colorpicker',
      text: 'Personalização',
    },
    {
      id: '4',
      onClick: () => {
        setConciliationTasksModal(true)
      },
      icon: 'gear',
      text: 'Tasks Conciliação',
    },
    {
      id: '5',
      onClick: () => {
        setDefaultSplitFeeModal(true)
      },
      icon: 'payments',
      text: 'Configuração de tarifas',
    },
    {
      id: '6',
      onClick: () => {
        history.push('/reconciliation')
      },
      icon: 'pixiconoutline',
      text: 'Conciliador PIX',
    },
  ]

  const renderOptions = () => {
    return options.map((option) => {
      if (isSpacePay() && option.id === '1') {
        return
      } else {
        return (
          <s.OptionsItem
            onClick={() => {
              if (option.onClick) {
                option.onClick()
              }
            }}
            data-testid={`option-${option.text.replace(/\s/gs, '-')}`}
            key={uuid()}
          >
            <s.Text color="graytheme6" bold>
              {option.text}
            </s.Text>
            {option.icon && (
              <s.Icon
                name={option.icon as keyof typeof dict}
                fill="graytheme6"
                width={21}
                height={21}
              />
            )}
          </s.OptionsItem>
        )
      }
    })
  }

  const checkMoreOptionClaim = (option) => {
    const cleanOption = option
      .normalize('NFD')
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .replace(/\s/g, '_')
      .replace(/^_/g, '')
      .toLowerCase()

    const optionClaim = `navbar_more_option_${cleanOption}`
    return checkUserClaim(currentUserSession?.user_claims, optionClaim)
  }

  const renderMoreOptions = () => {
    const options2render = moreOptions.filter((option) => {
      return checkMoreOptionClaim(option.text)
    })

    return options2render.map((option) => (
      <s.OptionsItem
        onClick={() => {
          if (option.onClick) {
            option.onClick()
          }
        }}
        data-testid={`option-${option.text.replace(/\s/gs, '-')}`}
        key={uuid()}
      >
        {option.icon && (
          <s.Icon
            name={option.icon as keyof typeof dict}
            fill="graytheme6"
            width={21}
            height={21}
          />
        )}
        <s.Text color="graytheme6" bold margin="0px 0px 0px 5px">
          {option.text}
        </s.Text>
      </s.OptionsItem>
    ))
  }

  return (
    <s.NavbarContainer data-testid="navbar-container">
      {editUserModal ? (
        <EditUserModal
          isOpen={editUserModal}
          handleClose={() => setEditUserModal('')}
        />
      ) : null}
      <UploadFileModal
        isOpen={changeLogoModal}
        handleClose={() => setChangeLogoModal(false)}
      />
      {defaultSplitFeeModal ? (
        <DefaultSplitFeeModal
          isOpen={defaultSplitFeeModal}
          handleClose={() => setDefaultSplitFeeModal(false)}
        />
      ) : null}
      <PrePaymentModal
        isOpen={antecipationModal}
        handleClose={() => setAntecipationModal('')}
      />
      <ConciliationTasksModal
        isOpen={conciliationTasksModal}
        handleClose={() => setConciliationTasksModal(false)}
      />
      <s.BlueVector1 />
      <s.BlueVector2 />
      <s.LogoExporter />
      <s.List>
        {menuItems
          .filter((x) => x.icon !== '')
          .map((page) => {
            return (
              <s.ListItem
                onClick={() => {
                  if (page.link === '/admin') {
                    removeImpersonate()
                  }
                }}
                data-testid={`list-item-${page.name}`}
                isSelected={page.link === location.pathname}
                key={uuid()}
              >
                <Link to={page.link}>
                  <s.Text bold type="headline" color="graytheme6">
                    <s.Icon
                      width={20}
                      height={20}
                      fill={
                        page.link === location.pathname
                          ? 'lightmaincolor1'
                          : 'graytheme6'
                      }
                      name={page.icon as keyof typeof dict}
                    />
                    {page.name}
                  </s.Text>
                </Link>
              </s.ListItem>
            )
          })}
        {checkUserClaim(
          currentUserSession?.user_claims,
          'panel_pages_outros'
        ) ? (
          <s.ListItem
            data-testid={`list-item-outros`}
            isSelected={'outros' === location.pathname}
            key={uuid()}
          >
            <s.MoreOptionsWrapper data-testid={`more-options-wrapper`}>
              <s.Align>
                <s.Icon
                  width={20}
                  height={20}
                  fill={
                    '/outros' === location.pathname ? 'maincolor' : 'graytheme6'
                  }
                  name={'dotsVertical' as keyof typeof dict}
                />
                <s.Text bold type="headline" color="graytheme6">
                  Mais
                </s.Text>
              </s.Align>
              <s.OptionsWrapper data-testid={'options-wrapper'}>
                {renderMoreOptions()}
              </s.OptionsWrapper>
            </s.MoreOptionsWrapper>
          </s.ListItem>
        ) : (
          ''
        )}
      </s.List>
      <s.LoginWrapper>
        {isLoggedIn ? (
          <>
            <s.UserWrapper>
              <s.Icon name="circlefilleduser" fill="maincolor" />
              <s.UserText bold type="headline" color="darkmaincolor1">
                {currentUserSession.name.split(' ')[0]}
              </s.UserText>
              <s.UserOptionsWrapper>{renderOptions()}</s.UserOptionsWrapper>
            </s.UserWrapper>
          </>
        ) : null}
      </s.LoginWrapper>
    </s.NavbarContainer>
  )
}

export default Navbar
