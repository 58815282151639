import React, { useEffect } from 'react'
import * as s from './styles'
import { isNotEmpty } from 'helpers/validators'
import { applyCnpjMask, formatMoney } from 'helpers/masks'
import {
  baseURLRegistrationPlatformCost,
  baseURLRegistrationSummarySplitFee,
  baseURLRegistrationV1SplitFee,
  get,
  patch,
  post,
} from 'services/api'
import Loading from './Loading'
import Success from './Success'
import SplitDetail from './SplitDetail'
import useGetLogo from 'hooks/useGetLogo'
import Error from './Error'

interface IProps {
  isOpen: boolean
  handleClose: () => any
}

export const STEPS_INITIAL_STATE = {
  form: false,
  error: false,
  success: false,
  loading: false,
}

export type Steps = typeof STEPS_INITIAL_STATE

export interface Form {
  total_split_fee_value: string
  split_fee_type: 'fixed' | 'percentage'
  split_fee_id: string
  pos_product_name: string
  pos_vendor_cnpj: string
  split_fee_value: number
  overpricing: number
  minimum_fee: number
}

export interface SplitFee {
  pos_product_name: string
  pos_vendor_cnpj: string
  split_fee_id: string
  platform_cost: number
  overpricing: number
  split_fee_value: number
  split_fee_type: string
  minimum_fee: number
  editable_minimum_fee: string
  platform_cost_is_editable: boolean
  split_default_is_editable: boolean
  total_split_fee_value: string
  editable_platform_cost: string
  pos_product_id: string
}

export const DefaultSplitFeeModal: React.FC<IProps> = ({
  isOpen,
  handleClose,
}) => {
  const [step, setStep] = React.useState({ ...STEPS_INITIAL_STATE, form: true })
  const [isLoading, setIsLoading] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  const [hasSplitFee, setHasSplitFee] = React.useState(false)
  const [selectedPsp, setSelectedPsp] = React.useState({
    id: '0',
    name: 'Selecionar',
    icon: '',
  })
  const [splitFee, setSplitFee] = React.useState<SplitFee>({
    pos_product_name: '',
    pos_vendor_cnpj: '',
    split_fee_id: '',
    platform_cost: null,
    overpricing: null,
    split_fee_value: null,
    split_fee_type: 'fixed',
    minimum_fee: null,
    editable_minimum_fee: '',
    platform_cost_is_editable: null,
    split_default_is_editable: null,
    total_split_fee_value: '',
    editable_platform_cost: '',
    pos_product_id: '',
  })
  const { logo } = useGetLogo()

  const [formErrors, setFormErrors] = React.useState<Partial<SplitFee>>({
    total_split_fee_value: '',
    editable_minimum_fee: '',
    editable_platform_cost: '',
  })

  const [infoError, setInfoError] = React.useState({
    title: '',
    message: <></>,
  })

  useEffect(() => {
    if (isOpen) {
      if (window.hj) {
        window.hj('stateChange', 'modal_split_fee')
      }

      if (window.gtag) {
        window.gtag('event', 'page_view', {
          page_path: `modal_split_fee`,
        })
      }
    }
  }, [isOpen])

  const infoErrorHandler = (type, errors) => {
    setFormErrors(errors)
    const objKeysTranslation = {
      total_split_fee_value: 'Tarifa padrão',
      editable_platform_cost: 'Custo de plataforma',
      editable_minimum_fee: 'Tarifa mínima',
    }
    const fieldsTranslated = Object.entries(errors)
      .map((error) =>
        error[1] !== '' ? objKeysTranslation[error[0]] : undefined
      )
      .filter((error) => error)
    if (type === 'emptyFields') {
      let requiredFields = ''
      if (fieldsTranslated.length > 2) {
        requiredFields = `${fieldsTranslated
          .slice(0, -1)
          .join(', ')} e ${fieldsTranslated.slice(-1)}`
      } else if (fieldsTranslated.length === 2) {
        requiredFields = `${fieldsTranslated.join(' e ')}`
      } else {
        requiredFields = fieldsTranslated[0]
      }
      setInfoError({
        title: 'Campo obrigatório não preenchido',
        message: (
          <>
            Preencha o campo de <u>{requiredFields}</u> para prosseguir.
          </>
        ),
      })
    }
    if (type === 'invalidValue') {
      let requiredFields = ''
      if (fieldsTranslated.length > 2) {
        requiredFields = `${fieldsTranslated
          .slice(0, -1)
          .join(', ')} e ${fieldsTranslated.slice(-1)}`
      } else if (fieldsTranslated.length === 2) {
        requiredFields = `${fieldsTranslated.join(' e ')}`
      } else {
        requiredFields = fieldsTranslated[0]
      }
      setInfoError({
        title: 'Campo inválido',
        message: (
          <>
            O campo de <u>{requiredFields}</u> deve ser maior que o custo de
            plataforma.
          </>
        ),
      })
    }
  }

  const InfoBox: React.FC<{
    error?: { title: string; message: React.ReactElement }
  }> = ({ error }) => {
    if (error?.title) {
      return (
        <s.InfoBox error>
          <s.Text bold type="paragraph" color="redshipay">
            {error.title}
          </s.Text>
          <s.Text type="paragraph" color="cancelledStatusTextColor">
            {error.message}
          </s.Text>
        </s.InfoBox>
      )
    }

    return <></>
  }

  const checkIfHasSplitFee = async (pspProvider: string) => {
    setHasError(false)
    setIsLoading(true)
    await get(
      `${baseURLRegistrationSummarySplitFee}?psp_provider=${pspProvider}`
    )
      .then((res) => {
        if (res.data.split_fee_id) {
          setSplitFee({
            ...res.data,
            total_split_fee_value: formatMoney(
              res.data.split_fee_value.toFixed(2)
            ),
            editable_platform_cost: formatMoney(
              res.data.platform_cost.toFixed(2)
            ),
            editable_minimum_fee: res.data.minimum_fee
              ? formatMoney(res.data.minimum_fee.tofixed(2))
              : null,
          })

          setHasSplitFee(true)
        } else {
          setSplitFee({
            ...res.data,
            total_split_fee_value: formatMoney(
              res.data.split_fee_value.toFixed(2)
            ),
            editable_platform_cost: formatMoney(
              res.data.platform_cost.toFixed(2)
            ),
            editable_minimum_fee: res.data.minimum_fee
              ? formatMoney(res.data.minimum_fee.tofixed(2))
              : null,
          })
          setHasSplitFee(false)
        }
      })
      .catch((e) => {
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const hasPlatformCostChanged = () => {
    return (
      parseFloat(
        splitFee.editable_platform_cost
          .replace(/[R$.]/g, '')
          .trim()
          .replace(',', '.')
      ) !== splitFee.platform_cost
    )
  }

  const patchPlatFormCost = async (platformCost, pspName, pspUuid) => {
    await patch(baseURLRegistrationPlatformCost, {
      psp_provider: pspName,
      psp_service: { value: platformCost },
      is_default_platform_cost: false,
      pos_product_id: pspUuid,
    })
      .then((res) => {
        setStep({ ...STEPS_INITIAL_STATE, success: true })
      })
      .catch((e) => {
        setStep({ ...STEPS_INITIAL_STATE, error: true })
      })
  }

  const postSplitFeeValue = async (url, payload) => {
    if (hasSplitFee) {
      await post(url, payload)
        .then((res) => {
          setStep({ ...STEPS_INITIAL_STATE, success: true })
        })
        .catch((e) => {
          setStep({ ...STEPS_INITIAL_STATE, error: true })
        })

        .finally(() => {
          setIsLoading(false)
        })
    } else {
      await post(url, payload)
        .then((res) => {
          setStep({ ...STEPS_INITIAL_STATE, success: true })
        })
        .catch((e) => {
          setStep({ ...STEPS_INITIAL_STATE, error: true })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  const handleSplitFeeRequest = async () => {
    const errors = { ...formErrors }
    const {
      split_fee_type,
      overpricing,
      pos_product_name,
      pos_vendor_cnpj,
      split_fee_id,
      minimum_fee,
      platform_cost,
      split_fee_value,
      platform_cost_is_editable,
      split_default_is_editable,
      ...requiredSplitFeeFields
    } = splitFee

    for (const [key, value] of Object.entries(requiredSplitFeeFields)) {
      if (key === 'editable_platform_cost') {
        errors[key] = isNotEmpty((value as string).replace('R$', '').trim())
          ? ''
          : 'Campo Obrigatório'
      }
      if (split_fee_type === 'fixed' && key === 'editable_minimum_fee') {
        continue
      }

      if (!errors[key]) {
        errors[key] = isNotEmpty(value) ? '' : 'Campo obrigatório'
      }
    }

    if (
      splitFee.split_fee_type === 'fixed' &&
      errors.total_split_fee_value === ''
    ) {
      if (
        parseFloat(
          splitFee.total_split_fee_value
            .replace(/[R$.]/g, '')
            .trim()
            .replace(',', '.')
        ) <
        parseFloat(
          splitFee.editable_platform_cost
            .replace(/[R$.]/g, '')
            .trim()
            .replace(',', '.')
        )
      ) {
        errors.total_split_fee_value = 'Campo inválido'
      }
    }

    if (
      splitFee.split_fee_type === 'percentage' &&
      errors.editable_minimum_fee === ''
    ) {
      if (
        parseFloat(
          splitFee.editable_minimum_fee
            .replace(/[R$.]/g, '')
            .trim()
            .replace(',', '.')
        ) <
        parseFloat(
          splitFee.editable_platform_cost
            .replace(/[R$.]/g, '')
            .trim()
            .replace(',', '.')
        )
      ) {
        errors.editable_minimum_fee = 'Campo inválido'
      }
    }

    const errList = Object.values(errors).filter((x) => x !== '')
    if (!errList || errList.length === 0) {
      setInfoError({ title: '', message: <></> })
      setStep({ ...STEPS_INITIAL_STATE, loading: true })

      let url = hasSplitFee
        ? `${baseURLRegistrationV1SplitFee}/${splitFee.split_fee_id}`
        : baseURLRegistrationV1SplitFee
      const payload: {
        split_fee_type: string
        total_split_fee_value: number
        minimum_fee?: number
      } = {
        split_fee_type: splitFee.split_fee_type,
        total_split_fee_value: parseFloat(
          splitFee.total_split_fee_value
            .replace(/[R$.]/g, '')
            .trim()
            .replace(',', '.')
        ),

        // platform_cost: parseFloat(
        //   splitFee.editable_platform_cost
        //     .replace(/[R$.]/g, '')
        //     .trim()
        //     .replace(',', '.')
        // ),
      }

      if (splitFee.split_fee_type === 'percentage') {
        payload.minimum_fee = parseFloat(
          splitFee.editable_minimum_fee
            .replace(/[R$.]/g, '')
            .trim()
            .replace(',', '.')
        )
      }

      if (hasPlatformCostChanged()) {
        patchPlatFormCost(
          parseFloat(
            splitFee.editable_platform_cost
              .replace(/[R$.]/g, '')
              .trim()
              .replace(',', '.')
          ),
          selectedPsp.name === 'Celcoin' ? 'celcoin' : 'itau_anybank_v2',
          splitFee.pos_product_id
        )
      } else {
        postSplitFeeValue(url, payload)
      }
    } else {
      if (errList.some((err) => err === 'Campo obrigatório')) {
        infoErrorHandler('emptyFields', errors)
      } else if (errList.some((err) => err === 'Campo inválido')) {
        infoErrorHandler('invalidValue', errors)
      }
    }
  }

  const renderContent = (step: Steps) => {
    if (step.loading) {
      return (
        <Loading data-testid="loading" step={step} hasSplitFee={hasSplitFee} />
      )
    }

    if (step.error) {
      return <Error />
    }

    if (step.success) {
      return (
        <Success
          hasSplitFee={hasSplitFee}
          platformCost={splitFee.editable_platform_cost}
          posName={splitFee.pos_product_name}
          pspName={
            selectedPsp.name === 'Celcoin'
              ? 'Celcoin'
              : 'Itau QR Code Multibancos'
          }
          splitFeeType={splitFee.split_fee_type}
          splitFeeValue={splitFee.total_split_fee_value}
        />
      )
    }

    return (
      <s.Sections>
        <s.SectionFormWrapper data-testid="form-default-split-fee">
          <s.Section>
            <InfoBox error={infoError} />

            <s.HeaderWrapper>
              <s.Icon fill="graytheme8" name="payments" />
              <s.Text
                bold
                margin="0 0 0 8px"
                color="graytheme6"
                type="headline"
              >
                Consultar tarifa padrão de transação
              </s.Text>
            </s.HeaderWrapper>
            <s.Text
              margin="16px 0 0px 0"
              fontWeight={400}
              type="paragraph"
              color="graytheme6"
            >
              Selecione uma instituição financeira para consultar sua tarifa
            </s.Text>
            <s.SectionLine>
              <s.Text
                fontWeight={600}
                type="headline"
                color={
                  formErrors.total_split_fee_value ? 'redshipay' : 'graytheme6'
                }
              >
                Sistema de automação
              </s.Text>
              <s.InputText
                width={260}
                disabled
                testId="form-pos-product-name"
                value={logo.pos_product_name}
                onChange={(e) => {}}
              />
            </s.SectionLine>
            <s.SectionLine>
              <s.Text fontWeight={600} type="headline">
                Instituição financeira
              </s.Text>
              <s.Select
                width={260}
                testId="psp-provider-select"
                selected={selectedPsp}
                options={[
                  {
                    id: '1',
                    text: 'Itaú',
                    icon: 'itauminiicon',
                    onClick: () => {
                      setSelectedPsp({
                        id: '1',
                        name: 'Itaú',
                        icon: 'itauminiicon',
                      })
                      setFormErrors({
                        total_split_fee_value: '',
                        editable_minimum_fee: '',
                        editable_platform_cost: '',
                      })
                      setInfoError({ title: '', message: <></> })
                      checkIfHasSplitFee('itau_anybank_v2')
                    },
                  },
                  {
                    id: '2',
                    text: 'Celcoin',
                    icon: 'celcoinminiicon',
                    onClick: () => {
                      setSelectedPsp({
                        id: '2',
                        name: 'Celcoin',
                        icon: 'celcoinminiicon',
                      })
                      setFormErrors({
                        total_split_fee_value: '',
                        editable_minimum_fee: '',
                        editable_platform_cost: '',
                      })
                      setInfoError({ title: '', message: <></> })
                      checkIfHasSplitFee('celcoin')
                    },
                  },
                ]}
              />
            </s.SectionLine>
          </s.Section>
          <s.Line />
          {hasError ? (
            <>
              <s.Text margin="32px 0 0 0" type="headline">
                Houve um erro em carregar as informações, tente novamente mais
                tarde
              </s.Text>
            </>
          ) : (
            <></>
          )}
          {isLoading ? (
            <Loading
              data-testid="loading"
              step={step}
              hasSplitFee={hasSplitFee}
            />
          ) : (
            <></>
          )}
          {selectedPsp.id !== '0' && !isLoading && !hasError ? (
            <SplitDetail
              splitFee={splitFee}
              setSplitFee={setSplitFee}
              pspName={selectedPsp.name}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
          ) : (
            <></>
          )}
        </s.SectionFormWrapper>
      </s.Sections>
    )
  }

  const renderButtons = (step: Steps) => {
    if (step.form) {
      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              data-testid="back-button"
              onClick={() => {
                handleClose()
              }}
              width="304px"
              height="42px"
              color="whitedetailed"
            >
              <s.Icon name="arrowleft" fill="maincolor" />
              <s.Text color="graytheme8" type="headline">
                Voltar
              </s.Text>
            </s.Button>
            <s.Button
              data-testid="handle-split-fee-button"
              width="304px"
              height="42px"
              disabled={isLoading}
              onClick={() => {
                handleSplitFeeRequest()
              }}
            >
              <s.Text margin="0 8px 0 0" color="white" type="headline">
                Continuar
              </s.Text>
              {isLoading ? (
                <s.Loading type="spinner" color="maincolor" />
              ) : (
                <s.Icon name="arrowright" fill="white" />
              )}
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }

    if (step.success) {
      return (
        <s.PreviousNextButtonContainer>
          <s.Div></s.Div>
          <s.PreviousNextButtonWrapper>
            <s.Button
              data-testid="success-close-button"
              width="100%"
              height="42px"
              color="maincolor"
              onClick={() => handleClose()}
            >
              <s.Text margin="0 8px 0 0" color="white" type="headline">
                Finalizar
              </s.Text>
            </s.Button>
          </s.PreviousNextButtonWrapper>
        </s.PreviousNextButtonContainer>
      )
    }

    return (
      <s.PreviousNextButtonContainer>
        <s.Div></s.Div>
        <s.PreviousNextButtonWrapper>
          <s.Button
            data-testid="close-button"
            width="100%"
            height="42px"
            color="whitedetailed"
            onClick={() => handleClose()}
          >
            <s.Text margin="0 8px 0 0" color="graytheme8" type="headline">
              Fechar
            </s.Text>
          </s.Button>
        </s.PreviousNextButtonWrapper>
      </s.PreviousNextButtonContainer>
    )
  }

  return (
    <>
      <s.Modal
        handleClose={handleClose}
        isOpen={isOpen}
        modalSubtitle="Configuração de splits"
      >
        <s.ModalContent data-testid="default-split-modal">
          {renderContent(step)}
          {renderButtons(step)}
        </s.ModalContent>
      </s.Modal>
    </>
  )
}
