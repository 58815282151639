import React from 'react'

import paitausuccess2 from '../../../assets/images/pa-itau-success2.png'

import * as s from './styles'

const Success: React.FC<{
  hasSplitFee: boolean
  pspName: string
  posName: string
  splitFeeType: string
  splitFeeValue: string
  platformCost: string
}> = ({
  hasSplitFee,
  pspName,
  posName,
  splitFeeType,
  splitFeeValue,
  platformCost,
}) => {
  return (
    <s.SuccessWrapper data-testid="success-modal">
      <s.Text margin="32px 0 32px 0" type="heading4" bold color="graytheme6">
        {`Tarifa ${hasSplitFee ? 'atualizada' : 'cadastrada'} com sucesso!`}
      </s.Text>

      <s.Text margin="0 0 32px 0" type="headline" color="graytheme6">
        A tarifa padrão para transações do Pix {pspName} do sistema{' '}
        <u>{posName}</u> foi configurada com sucesso como{' '}
        <u>valor {splitFeeType === 'fixed' ? 'fixo' : 'percentual'}</u> de{' '}
        <u>
          {splitFeeValue}
          {splitFeeType === 'percentage' ? ' %' : ''}
        </u>
        , com custo de plataforma de <u>{platformCost}</u>.
      </s.Text>
      <s.Text
        margin="0 0 16px 0"
        fontWeight={500}
        type="headline"
        color="graytheme6"
      >
        Agora é possível definir tarifas customizadas para clientes específicos
        acessando a aba Pix de suas contas.
      </s.Text>

      <s.ImageWrapper>
        <img src={paitausuccess2} alt="Imagem de Sucesso Pix AnyBank" />
      </s.ImageWrapper>
    </s.SuccessWrapper>
  )
}
export default Success
