export const isNotEmpty = (value: string | number | boolean) => {
  return !value || value === '' ? false : true
}

export const isNotEmptyStringOrFile = (value: string | File) => {
  return !value || value === '' || value === null || value === undefined
    ? false
    : true
}

export const isValidCpfCnpj = (value: string) => {
  if (!value) return false
  return isValidCpf(value) || isValidCnpj(value)
}

export const isValidCnpj = (value: string) => {
  if (!value) return false

  value = value.replace(/\D/g, '')

  if (value.length != 14) return false

  if (
    value == '00000000000000' ||
    value == '11111111111111' ||
    value == '22222222222222' ||
    value == '33333333333333' ||
    value == '44444444444444' ||
    value == '55555555555555' ||
    value == '66666666666666' ||
    value == '77777777777777' ||
    value == '88888888888888' ||
    value == '99999999999999'
  )
    return false

  let size = value.length - 2
  let nums = value.substring(0, size)
  let digits = value.substring(size)

  let sum = 0
  let pos = size - 7
  for (let i = size; i >= 1; i--) {
    sum += parseInt(nums.charAt(size - i)) * pos--
    if (pos < 2) pos = 9
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
  if (result != parseInt(digits.charAt(0))) return false

  size = size + 1
  nums = value.substring(0, size)
  sum = 0
  pos = size - 7
  for (let i = size; i >= 1; i--) {
    sum += parseInt(nums.charAt(size - i)) * pos--
    if (pos < 2) pos = 9
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
  if (result != parseInt(digits.charAt(1))) return false

  return true
}

export const isValidEmail = (value: string) => {
  if (!value) return false

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!re.test(value.toLowerCase())) return false
  return true
}

export const isValidPhone = (value: string) => {
  if (!value) return false

  const cleanPhoneStr = value.replace(/[^0-9()+\- ]/g, '')

  const digitsCount = (cleanPhoneStr.match(/\d/g) || []).length
  if (digitsCount < 10 || digitsCount > 15) return false

  /**
   * this regex matches:
   * - An area code that may be enclosed in parentheses (e.g., (11)).
   * - An optional space or hyphen after the area code.
   * - An optional country code preceded by a "+" (e.g., +55).
   * - A series of digits that can start with any number from 1-9 for non-mobile
   * numbers (landlines), following a sequence of three more digits, an optional
   * hyphen or space, and four digits at the end (e.g., 1234-5678).
   * - Alternatively, it matches a mobile format starting with 9 (e.g., 9xxxx-yyyy).
   */
  const phoneRegex =
    /^(?:\+?\d{1,3}[\s-]?)?(\(?\d{2}\)?[\s-]?)?([2-8]\d{3}[\s-]?\d{4}|9\d{4}[\s-]?\d{4}|\d{10,11})$/

  return phoneRegex.test(cleanPhoneStr)
}

export const isValidPassword = (password: string) => {
  if (!password) return false

  const regex = /^(?=.*[A-Z])(?=.*[!@#$&*0-9])(?=.*[a-z]).{6,}/
  if (regex.test(password)) return true
  return false
}

export const isValidCpf = (value: string) => {
  if (!value) return false
  const cpfStr = value.replace(/\D/g, '')
  if (
    !cpfStr ||
    cpfStr.length !== 11 ||
    cpfStr === '00000000000' ||
    cpfStr === '11111111111' ||
    cpfStr === '22222222222' ||
    cpfStr === '33333333333' ||
    cpfStr === '44444444444' ||
    cpfStr === '55555555555' ||
    cpfStr === '66666666666' ||
    cpfStr === '77777777777' ||
    cpfStr === '88888888888' ||
    cpfStr === '99999999999'
  ) {
    return false
  }

  let sum = 0
  let remainder: number

  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(cpfStr.substring(i - 1, i)) * (11 - i)
  remainder = (sum * 10) % 11

  if (remainder === 10 || remainder === 11) remainder = 0
  if (remainder !== parseInt(cpfStr.substring(9, 10))) return false
  sum = 0
  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(cpfStr.substring(i - 1, i)) * (12 - i)
  remainder = (sum * 10) % 11
  if (remainder === 10 || remainder === 11) remainder = 0
  if (remainder !== parseInt(cpfStr.substring(10, 11))) return false

  return true
}

export const isValidBirthDate = (dateString) => {
  const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
  if (!regex.test(dateString)) {
    return false
  }

  return true
}
