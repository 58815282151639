import React from 'react'
import * as s from './styles'
import { Form, SplitFee } from '.'
import { formatMoney, formatPercentage } from 'helpers/masks'

const SplitDetail: React.FC<{
  splitFee: SplitFee
  setSplitFee: React.Dispatch<React.SetStateAction<SplitFee>>
  pspName: string
  formErrors: Partial<SplitFee>
  setFormErrors: React.Dispatch<React.SetStateAction<Partial<SplitFee>>>
}> = ({ splitFee, setSplitFee, pspName, formErrors, setFormErrors }) => {
  const currentSplitFeeValue =
    splitFee.split_fee_value > 0 || splitFee.split_fee_value !== null
      ? formatMoney(splitFee.split_fee_value.toFixed(2))
      : 'Não definida'

  return (
    <s.Section>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={formErrors.total_split_fee_value ? 'redshipay' : 'graytheme6'}
        >
          Tarifa padrão atual
        </s.Text>
        <s.InputText
          width={260}
          disabled
          testId="form-current-total-split-fee-value"
          value={currentSplitFeeValue}
          onChange={(e) => {}}
        />
      </s.SectionLine>
      <s.HeaderWrapper style={{ marginTop: '32px' }}>
        <s.Icon fill="graytheme8" name="payments" />
        <s.Text
          fontWeight={400}
          margin="0px 0 0 8px"
          color="graytheme6"
          type="headline"
        >
          Nova tarifa padrão
        </s.Text>
      </s.HeaderWrapper>
      <s.Text
        margin="16px 0 0px 0"
        fontWeight={400}
        type="paragraph"
        color="graytheme6"
      >
        A tarifa padrão será descontada por Pix liquidado de todos os clientes
        da automação.
      </s.Text>
      <s.Text
        margin="16px 0 0px 0"
        fontWeight={400}
        type="paragraph"
        color="graytheme6"
      >
        Será possível definir tarifas customizadas para clientes específicos
        acessando a aba Pix.
      </s.Text>
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={formErrors.total_split_fee_value ? 'redshipay' : 'graytheme6'}
        >
          Modalidade da tarifa
        </s.Text>
        <s.ComissionType>
          <s.RadioWrapper disabled={false}>
            <s.RadioButton
              id={'1'}
              data-testid="radio-button-1"
              checked={splitFee.split_fee_type === 'fixed'}
              handleChange={() => {
                setSplitFee({
                  ...splitFee,
                  split_fee_type: 'fixed',
                  total_split_fee_value: '',
                  editable_minimum_fee: '',
                })
              }}
              name="fixedValue"
              value={splitFee.split_fee_type === 'fixed'}
            />
            <s.Text bold>Valor Fixo</s.Text>
          </s.RadioWrapper>
          <s.RadioWrapper disabled={pspName === 'Itaú'}>
            <s.RadioButton
              id={'2'}
              data-testid="radio-button-2"
              checked={splitFee.split_fee_type === 'percentage'}
              handleChange={() => {
                setSplitFee({
                  ...splitFee,
                  split_fee_type: 'percentage',
                  total_split_fee_value: '',
                  editable_minimum_fee: '',
                })
              }}
              name="percentageValue"
              disabled={pspName === 'Itaú'}
              value={splitFee.split_fee_type === 'percentage'}
            />
            <s.Text bold>Percentual</s.Text>
          </s.RadioWrapper>
        </s.ComissionType>
      </s.SectionLine>
      {splitFee.split_fee_type === 'fixed' ? (
        <s.SectionLine>
          <s.Text
            fontWeight={600}
            type="headline"
            color={
              formErrors.total_split_fee_value ? 'redshipay' : 'graytheme6'
            }
          >
            Tarifa padrão
          </s.Text>
          <s.InputText
            width={260}
            disabled={!splitFee.split_default_is_editable}
            testId="form-total-split-fee-value"
            value={splitFee.total_split_fee_value}
            onChange={(e) => {
              setSplitFee({
                ...splitFee,
                total_split_fee_value: formatMoney(e.target.value),
              })
            }}
            error={
              formErrors.total_split_fee_value
                ? { message: formErrors.total_split_fee_value }
                : false
            }
            suffix={
              formErrors.total_split_fee_value ? (
                <s.Icon name="alertcirclefilled" fill="lightred2" />
              ) : (
                <></>
              )
            }
          />
        </s.SectionLine>
      ) : (
        <s.SectionLine>
          <s.Text
            fontWeight={600}
            type="headline"
            color={
              formErrors.total_split_fee_value ? 'redshipay' : 'graytheme6'
            }
          >
            Tarifa padrão
          </s.Text>
          <s.InputText
            width={260}
            value={splitFee.total_split_fee_value}
            disabled={!splitFee.split_default_is_editable}
            testId="form-total-split-fee-value-percentage"
            maxLength={5}
            suffix={
              <s.Text bold type="headline">
                %
              </s.Text>
            }
            onChange={(e) => {
              setSplitFee({
                ...splitFee,
                total_split_fee_value: formatPercentage(e.target.value),
              })
            }}
          />
        </s.SectionLine>
      )}
      <s.SectionLine>
        <s.Text
          fontWeight={600}
          type="headline"
          color={formErrors.total_split_fee_value ? 'redshipay' : 'graytheme6'}
        >
          Custo de plataforma
        </s.Text>
        <s.InputText
          width={260}
          disabled={!splitFee.platform_cost_is_editable}
          testId="form-platform-cost"
          value={splitFee.editable_platform_cost}
          onChange={(e) => {
            setSplitFee({
              ...splitFee,
              editable_platform_cost: formatMoney(e.target.value),
            })
          }}
          error={
            formErrors.editable_platform_cost
              ? { message: formErrors.editable_platform_cost }
              : false
          }
          suffix={
            formErrors.editable_platform_cost ? (
              <s.Icon name="alertcirclefilled" fill="lightred2" />
            ) : (
              <></>
            )
          }
        />
      </s.SectionLine>
      {splitFee.split_fee_type === 'percentage' ? (
        <s.SectionLine>
          <s.Align>
            <s.Text
              fontWeight={600}
              type="headline"
              color={
                formErrors.total_split_fee_value ? 'redshipay' : 'graytheme6'
              }
            >
              Tarifa mínima
            </s.Text>
            <s.Hover>
              <s.Icon fill="maincolor" name="questionmarkcircle" />
              <s.TooltipText>
                Caso o percentual da tarifa resulte em menos do que este valor,
                será cobrada a tarifa mínima. Sua tarifa mínima deverá ser
                superior ao Custo Plataforma para continuar.
              </s.TooltipText>
            </s.Hover>
          </s.Align>
          <s.InputText
            width={260}
            disabled={!splitFee.split_default_is_editable}
            testId="form-minimum-fee"
            value={splitFee?.editable_minimum_fee}
            onChange={(e) => {
              setSplitFee({
                ...splitFee,
                editable_minimum_fee: formatMoney(e.target.value),
              })
            }}
            error={
              formErrors.editable_minimum_fee
                ? { message: formErrors.editable_minimum_fee }
                : false
            }
            suffix={
              formErrors.editable_minimum_fee ? (
                <s.Icon name="alertcirclefilled" fill="lightred2" />
              ) : (
                <></>
              )
            }
          />
        </s.SectionLine>
      ) : (
        <></>
      )}
    </s.Section>
  )
}

export default SplitDetail
